import React, { useState } from 'react';
import '../flight-listing/flight-listing.css'
import TitleBanner from '../flight-booking/title-ban'
import FlightBookingMain from '../flight-booking/flight-booking-main/flight-booking-main'



const Flight_Booking = () => {
    return (
        <div className='flight-bookingss'>
            <TitleBanner />
            <FlightBookingMain />
        </div>
    )
}

export default Flight_Booking