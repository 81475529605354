import logo from './logo.svg';
import './assets/css/bootstrap.min.css'
import './assets/css/font-awesome.css'
import './assets/css/slick-theme.css'
import './assets/css/slick.css'
import './App.css';
import Header from './component/header/header'
import SidebarMenu from './component/sidebar-menu/sidebarmenu'
import Home from './pages/home/home'
import Contactus from './pages/contact-us/contact-us'
import AboutUs from './pages/about-us/about-us'
import FlightListing from './pages/flight-listing/flight-listing'
import FlightBooking from './pages/flight-booking/flight-booking'
import Login from './component/login-signin/login'
import Signin from './component/login-signin/signin'
import Privacy_Policy from './pages/privacy-policy/privacy-policy'
import ProfileMain from './pages/user/profile-main/profile-main'
import MyBookings from './pages/user/my-bookings/my-bookings'
import WalletHistory from './pages/user/wallet-history/wallet-history'
import Visa from './pages/user/visa/visa'
import Visas from './pages/user/visa/visa-f'

import { HashRouter, Route, Routes } from "react-router-dom";


function App() {

  return (
    <HashRouter>
      <Routes>
        <Route
          path="/"
          element={<Header pagename={""} page={<Home />} />}
        />
        <Route
          path="/Contact-us"
          element={<Header pagename={"Contact-us"} page={<Contactus />} />}
        />
        <Route
          path="/about-us"
          element={<Header pagename={"about-us"} page={<AboutUs />} />}
        />
        <Route
          path="/login"
          element={<Header pagename={"log-in"} page={<Login />} />}
        />
        <Route
          path="/signin"
          element={<Header pagename={"sign-in"} page={<Signin />} />}
        />
        <Route
          path="/flight-listing"
          element={<Header pagename={"flight-listing"} page={<FlightListing />} />}
        />
        <Route
          path="/flight-booking"
          element={<Header pagename={"flight-booking"} page={<FlightBooking />} />}
        />
        <Route
          path="/privacy-policy"
          element={<Header pagename={"privacy-policy"} page={<Privacy_Policy />} />}
        />
        <Route
          path="/user/profile-main"
          element={<Header pagename={"personal-information"} page={<ProfileMain />} />}
        />
        <Route
          path="/user/my-bookings"
          element={<Header pagename={"my-bookings"} page={<MyBookings />} />}
        />
        <Route
          path="/user/wallet-history"
          element={<Header pagename={"wallet-history"} page={<WalletHistory />} />}
        />
        <Route
          path="/user/visa"
          element={<Header pagename={"visa"} page={<Visa />} />}
        />
        <Route
          path="/visas"
          element={<Header pagename={"Visas"} page={<Visas />} />}
        />


      </Routes>
    </HashRouter>
  );



}

export default App;
