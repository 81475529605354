import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../component/nav-profile/nav-profile.css'
import '../profile-main/profile-main.css'
import ProfileSidebarWidget from '../profile-sidebar'
import { ToastContainer, toast } from "react-toastify";
import { get, post } from "../../../API/apiHelper";
import { users_profile, update_account,IMAGE_BASE_URL } from "../../../API/endpoints";
import profileimage from "../../../assets/images/profile.png";


const ProfileMain = () => {
    const navigate = useNavigate();
    const [profilePreview, setProfilePreview] = useState(profileimage); // Image preview state

    useEffect(() => {
        const userDataFromSession = sessionStorage.getItem('userData');
        if (userDataFromSession && userDataFromSession != null) {
            JSON.parse(userDataFromSession);
        } else {
            navigate("/");
        }

    }, []);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile_no: '',
        country: '',
        dob: '',
        gender: '',
        address: '',
    });

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await get(users_profile, true);
                if (!response.ok) {
                    const errorMsg = await response.text();
                    throw new Error(`Error ${response.status}: ${errorMsg}`);
                }
                const data = await response.json();

                setFormData(prev => ({
                    ...prev,
                    name: data.data.name,
                    email: data.data.email,
                    mobile_no: data.data.mobile_no,
                    country: data.data.country,
                    dob: data.data.dob,
                    gender: data.data.gender,
                    address: data.data.address,
                    profile_photo: data.data.profile_photo,

                }));
                setProfilePreview( `${IMAGE_BASE_URL}${data.data.profile_photo}`  || profileimage);

            } catch (error) {
                console.error('Failed to fetch user data:', error);
            }
        };
        fetchUserData();
    }, []);




    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };


    const [imageData, setImageData] = useState({
        profile_photo: '',
    });

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setImageData({
            ...imageData,
            profile_photo: file
        });

        // For image preview
        const reader = new FileReader();
        reader.onloadend = () => {
            setProfilePreview(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const updatedata = JSON.stringify(formData)
            const response = await post(update_account, { data: updatedata ,user_profile:imageData.profile_photo}, true);
            const data = await response.json();
            if (data.status == false) {
                toast.error(data.message);
            } else {
                toast.success(data.message);
            }
        } catch (error) {
            console.error(error);
            toast.error('Error updating profile. Please try again.');
        }
    };





    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        try {
            const updatedata = JSON.stringify(formData)
            const response = await post(update_account, { data: updatedata }, true);
            const data = await response.json();
            if (data.status == false) {
                toast.error(data.message);
            } else {
                toast.success(data.message);
            }
        } catch (error) {
            console.error(error);
            toast.error('Error updating profile. Please try again.');
        }
    };



    return (
        <section className="pt-3 pb-5">
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className="container">

                <div className="row">
                    <ProfileSidebarWidget />
                    <div className="col-xl-9 col-lg-8">
                        <div className="d-grid mb-0 d-lg-none w-100">
                            <button type="button" className="mb-4 items-center justify-content-center gap-1 btn btn-primary menu-btnspt">
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M496 384H160v-16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h80v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h336c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm0-160h-80v-16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h336v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h80c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm0-160H288V48c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16C7.2 64 0 71.2 0 80v32c0 8.8 7.2 16 16 16h208v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h208c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16z"></path>
                                </svg> Menu
                            </button>
                        </div>


                        <div className="vstack gap-4">

                            {/* Personal Information Card */}
                            <div className="border card">
                                <div className="border-bottom card-header">
                                    <h3 className="card-header-title">Personal Information</h3>
                                </div>
                                <div className="card-body">
                                    <form className="row g-3" onSubmit={handleSubmit}>
                                        <div className="col-12">
                                            <label className="form-label">Upload your profile photo<span className="text-danger">*</span></label>
                                            <div className="d-flex align-items-center">
                                                <label className="position-relative me-4" htmlFor="uploadfile-1" title="Replace this pic">
                                                    <span className="avatar avatar-xl">
                                                        <img id="uploadfile-1-preview" src={profilePreview} className="avatar-img rounded-circle border border-white border-3 shadow" alt="profile" />
                                                    </span>
                                                </label>
                                                <label className="btn btn-sm btn-primary-soft mb-0" htmlFor="uploadfile-1">Change</label>
                                                <input id="uploadfile-1" name='user_profile' className="form-control d-none" type="file" onChange={handleFileChange}/>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-label">Full Name*</label>
                                            <input placeholder="Enter your full name" name="name" className="form-control" value={formData?.name || ''} onChange={handleChange} />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-label">Email address*</label>
                                            <input placeholder="Enter your email id" name="email" type="email" className="form-control" value={formData?.email || ''} onChange={handleChange} />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-label">Mobile number*</label>
                                            <input placeholder="Enter your mobile number" name="mobile_no" className="form-control" value={formData?.mobile_no || ''} onChange={handleChange} />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-label">Nationality<span className="text-danger">*</span></label>
                                            <select className="form-select" name="country" value={formData.country} onChange={handleChange}>
                                                <option value="Paris">Paris</option>
                                                <option value="USA">USA</option>
                                                <option value="India">India</option>
                                                <option value="UK">UK</option>
                                            </select>
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-label">Date of Birth<span className="text-danger">*</span></label>
                                            <input type="date" className="form-control" name="dob" placeholder="Enter Date of Birth" value={formData?.dob} onChange={handleChange} />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-label">Select Gender<span className="text-danger">*</span></label>
                                            <div className="d-flex gap-4">
                                                <div className="form-check radio-bg-light">
                                                    <input className="form-check-input" type="radio" name="gender" value="Male" checked={formData.gender === 'Male'} onChange={handleChange} />
                                                    <label className="form-check-label">Male</label>
                                                </div>
                                                <div className="form-check radio-bg-light">
                                                    <input className="form-check-input" type="radio" name="gender" value="Female" checked={formData.gender === 'Female'} onChange={handleChange} />
                                                    <label className="form-check-label">Female</label>
                                                </div>
                                                <div className="form-check radio-bg-light">
                                                    <input className="form-check-input" type="radio" name="gender" value="Others" checked={formData.gender === 'Others'} onChange={handleChange} />
                                                    <label className="form-check-label">Others</label>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-12">
                                            <label className="form-label">Address</label>
                                            <textarea rows="3" spellCheck="false" name="address" className="form-control" value={formData.address} onChange={handleChange}></textarea>
                                        </div>

                                        <div className="text-end col-12">
                                            <button type="submit" className="mb-0 cus-btn">Save Changes</button>
                                        </div>
                                    </form>
                                </div>
                            </div>


                            {/* Update Password Card */}
                            <div className="border card">
                                <div className="border-bottom card-header">
                                    <h4 className="card-header-title">Update Password</h4>
                                </div>
                                <form className="card-body" onSubmit={handleSubmit}>
                                    <div className="position-relative mb-3">
                                        <label className="form-label">Current password</label>
                                        <input placeholder="Enter current password" name="currentPassword" type="password" className="form-control" value={formData.currentPassword} onChange={handleChange} />
                                    </div>
                                    <div className="position-relative mb-3">
                                        <label className="form-label">Enter new password</label>
                                        <input placeholder="Enter new password" name="newPassword" type="password" className="form-control" value={formData.newPassword} onChange={handleChange} />
                                    </div>
                                    <div className="position-relative mb-3">
                                        <label className="form-label">Confirm new password</label>
                                        <input placeholder="Confirm new password" name="confirmPassword" type="password" className="form-control" value={formData.confirmPassword} onChange={handleChange} />
                                    </div>
                                    <div className="text-end">
                                        <button type="submit" className="mb-0 cus-btn">Change Password</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProfileMain;
