import React from 'react'

import Hero from '../home/hero/hero'
import Benefits from '../home/benefits/benefits'
// import FlightSlider from '../home/flight-slider/flight-slider'
import TravelUs from '../home/travel-us/travel-us'
import Achievments from '../home/achievments/achievments'
import Testimonial from '../home/testimonial/testimonial'
// import Footer from '../../component/footer/footer'

const home = () => {
    return (
        <div>
            <Hero />
            <Benefits />
            {/* <FlightSlider /> */}
            <TravelUs />
            <Achievments />
            <Testimonial />
            {/* <Footer /> */}
        </div>
    )
}


export default home