import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../flight-listing/flight-listing.css'
import '../flight-listing/flight-listing-main.css'
import '../flight-listing/booking-area-listing.css'
import TitleBanner from '../flight-listing/title-ban'
import {  post } from "../../API/airline";
import { AIR_IQ_SEARCH } from "../../API/endpoints";


const flightsData = [
    {
        airlineName: 'United Dubai Airlines',
        aircraft: 'Boeing 777-90',
        departureTime: '12:00',
        arrivalTime: '12:50',
        duration: '0h 50m',
        departure: 'DUB',
        arrival: 'SHJ',
        price: 240,
        date: 'Monday 14 August',
        image: 'https://flight.readytouse.in/assets/media/flight_icon/icon-1.png',
        stops: 1,
        operator: 'Tpm Line',
        flightClass: 'Economy',
        flightCode: 'FK234',
    },
    {
        airlineName: 'Feel Dubai Airline',
        aircraft: 'Boeing 777-90',
        departureTime: '12:00',
        arrivalTime: '12:50',
        duration: '0h 50m',
        departure: 'DUB',
        arrival: 'SHJ',
        price: 240,
        date: 'Monday 14 August',
        image: 'https://flight.readytouse.in/assets/media/flight_icon/icon-2.png',
        stops: 1,
        operator: 'Tpm Line',
        flightClass: 'Economy',
        flightCode: 'FK234',
    },
    {
        airlineName: 'United Dubai Airlines',
        aircraft: 'Boeing 777-90',
        departureTime: '12:00',
        arrivalTime: '12:50',
        duration: '0h 50m',
        departure: 'DUB',
        arrival: 'SHJ',
        price: 240,
        date: 'Monday 14 August',
        image: 'https://flight.readytouse.in/assets/media/flight_icon/icon-1.png',
        stops: 1,
        operator: 'Tpm Line',
        flightClass: 'Economy',
        flightCode: 'FK234',
    },
    {
        airlineName: 'Feel Dubai Airline',
        aircraft: 'Boeing 777-90',
        departureTime: '12:00',
        arrivalTime: '12:50',
        duration: '0h 50m',
        departure: 'DUB',
        arrival: 'SHJ',
        price: 240,
        date: 'Monday 14 August',
        image: 'https://flight.readytouse.in/assets/media/flight_icon/icon-2.png',
        stops: 1,
        operator: 'Tpm Line',
        flightClass: 'Economy',
        flightCode: 'FK234',
    },
    {
        airlineName: 'United Dubai Airlines',
        aircraft: 'Boeing 777-90',
        departureTime: '12:00',
        arrivalTime: '12:50',
        duration: '0h 50m',
        departure: 'DUB',
        arrival: 'SHJ',
        price: 240,
        date: 'Monday 14 August',
        image: 'https://flight.readytouse.in/assets/media/flight_icon/icon-1.png',
        stops: 1,
        operator: 'Tpm Line',
        flightClass: 'Economy',
        flightCode: 'FK234',
    },
    {
        airlineName: 'Feel Dubai Airline',
        aircraft: 'Boeing 777-90',
        departureTime: '12:00',
        arrivalTime: '12:50',
        duration: '0h 50m',
        departure: 'DUB',
        arrival: 'SHJ',
        price: 240,
        date: 'Monday 14 August',
        image: 'https://flight.readytouse.in/assets/media/flight_icon/icon-2.png',
        stops: 1,
        operator: 'Tpm Line',
        flightClass: 'Economy',
        flightCode: 'FK234',
    },
    {
        airlineName: 'United Dubai Airlines',
        aircraft: 'Boeing 777-90',
        departureTime: '12:00',
        arrivalTime: '12:50',
        duration: '0h 50m',
        departure: 'DUB',
        arrival: 'SHJ',
        price: 240,
        date: 'Monday 14 August',
        image: 'https://flight.readytouse.in/assets/media/flight_icon/icon-1.png',
        stops: 1,
        operator: 'Tpm Line',
        flightClass: 'Economy',
        flightCode: 'FK234',
    },
    {
        airlineName: 'Feel Dubai Airline',
        aircraft: 'Boeing 777-90',
        departureTime: '12:00',
        arrivalTime: '12:50',
        duration: '0h 50m',
        departure: 'DUB',
        arrival: 'SHJ',
        price: 240,
        date: 'Monday 14 August',
        image: 'https://flight.readytouse.in/assets/media/flight_icon/icon-2.png',
        stops: 1,
        operator: 'Tpm Line',
        flightClass: 'Economy',
        flightCode: 'FK234',
    },
    {
        airlineName: 'United Dubai Airlines',
        aircraft: 'Boeing 777-90',
        departureTime: '12:00',
        arrivalTime: '12:50',
        duration: '0h 50m',
        departure: 'DUB',
        arrival: 'SHJ',
        price: 240,
        date: 'Monday 14 August',
        image: 'https://flight.readytouse.in/assets/media/flight_icon/icon-1.png',
        stops: 1,
        operator: 'Tpm Line',
        flightClass: 'Economy',
        flightCode: 'FK234',
    },
    {
        airlineName: 'Feel Dubai Airline',
        aircraft: 'Boeing 777-90',
        departureTime: '12:00',
        arrivalTime: '12:50',
        duration: '0h 50m',
        departure: 'DUB',
        arrival: 'SHJ',
        price: 240,
        date: 'Monday 14 August',
        image: 'https://flight.readytouse.in/assets/media/flight_icon/icon-2.png',
        stops: 1,
        operator: 'Tpm Line',
        flightClass: 'Economy',
        flightCode: 'FK234',
    },
    {
        airlineName: 'United Dubai Airlines',
        aircraft: 'Boeing 777-90',
        departureTime: '12:00',
        arrivalTime: '12:50',
        duration: '0h 50m',
        departure: 'DUB',
        arrival: 'SHJ',
        price: 240,
        date: 'Monday 14 August',
        image: 'https://flight.readytouse.in/assets/media/flight_icon/icon-1.png',
        stops: 1,
        operator: 'Tpm Line',
        flightClass: 'Economy',
        flightCode: 'FK234',
    },
    {
        airlineName: 'Feel Dubai Airline',
        aircraft: 'Boeing 777-90',
        departureTime: '12:00',
        arrivalTime: '12:50',
        duration: '0h 50m',
        departure: 'DUB',
        arrival: 'SHJ',
        price: 240,
        date: 'Monday 14 August',
        image: 'https://flight.readytouse.in/assets/media/flight_icon/icon-2.png',
        stops: 1,
        operator: 'Tpm Line',
        flightClass: 'Economy',
        flightCode: 'FK234',
    },
    {
        airlineName: 'United Dubai Airlines',
        aircraft: 'Boeing 777-90',
        departureTime: '12:00',
        arrivalTime: '12:50',
        duration: '0h 50m',
        departure: 'DUB',
        arrival: 'SHJ',
        price: 240,
        date: 'Monday 14 August',
        image: 'https://flight.readytouse.in/assets/media/flight_icon/icon-1.png',
        stops: 1,
        operator: 'Tpm Line',
        flightClass: 'Economy',
        flightCode: 'FK234',
    },
];


const FlightItem = ({ flight, index, toggleExpand, expanded }) => {
    return (
        <div className="meri_marji">
            <div className="flight-block bg-white light-shadow p-24 br-10 mb-16">
                <div className="flight-area">
                    <div className="airline-name">
                        <img src={flight.image} alt={flight.airlineName} />
                        <div>
                            <h5 className="lightest-black mb-8">{flight.airlineName}</h5>
                            <h6 className="dark-gray">{flight.aircraft}</h6>
                        </div>
                    </div>
                    <div className="flight-detail">
                        <div className="flight-departure">
                            <h5 className="color-black">{flight.departureTime}</h5>
                            <h5 className="dark-gray text-end">{flight.departure}</h5>
                        </div>
                        <div className="d-inline-flex align-items-center gap-8">
                            <span>To</span>
                            <div className="from-to text-center">
                                <h5 className="dark-gray">{flight.duration}</h5>
                                <img className='f_icon_list' src="https://flight.readytouse.in/assets/media/icons/route-plan.png" alt="route-plan" />
                                <h6 className="color-black">{flight.stops} Stop</h6>
                            </div>
                            <span>From</span>
                        </div>
                        <div className="flight-departure">
                            <h5 className="color-black">{flight.arrivalTime}</h5>
                            <h5 className="dark-gray">{flight.arrival}</h5>
                        </div>
                    </div>
                    <div className="flight-button">
                        <div className="amount">
                            <h5 className="color-black">${flight.price}</h5>
                            <h6 className="dark-gray text-end">Price</h6>
                        </div>
                        <Link to="/flight-booking" className="cus-btn btn-sec">Book Now</Link>
                    </div>
                </div>
                <hr className="bg-light-gray mt-24 mb-24" />
                <div className="d-flex justify-content-between align-items-center">
                    <h5 className="color-black">{flight.date}</h5>
                    <div>
                        <button className="accordion-button color-primary h5 collapsed"
                            onClick={() => toggleExpand(index)}
                        >
                            <i className={`fal fa-chevron-${expanded ? 'up' : 'down'} color-primary`}></i>&nbsp;Flight Detail
                        </button>
                    </div>
                </div>
            </div>
            {
                expanded && (
                    <div className="accordion-collapse mb-32 collapse show">
                        <div className="row bg-white br-10 light-shadow p-24 m-0 align-items-center">
                            <div className="col-lg-3 col-sm-4">
                                <div className="time-detail">
                                    <h6 className="flight-date mb-32">{flight.date}</h6>
                                    <h6 className="color-black mb-8">{flight.departureTime}</h6>
                                    <h6 className="dark-gray mb-16">{flight.duration}</h6>
                                    <h6 className="dark-gray">{flight.arrivalTime}</h6>
                                </div>
                            </div>
                            <div className="col-lg-9 col-sm-8">
                                <div className="detail-block">
                                    <div className="d-sm-flex d-block align-items-center gap-24">
                                        <img src={flight.image} alt={flight.airlineName} />
                                        <div className="content">
                                            <h6 className="dark-gray">TPM Line</h6>
                                            <h6 className="dark-gray">{flight.operator}</h6>
                                            <h6 className="dark-gray">{flight.flightClass} | Flight {flight.flightCode} | Aircraft {flight.aircraft}</h6>
                                            <h6 className="dark-gray">Adult(s): 25KG luggage free</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {/* </div > */}
        </div>
    );
};

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const pageNumbers = [...Array(totalPages).keys()].map(num => num + 1);
    return (
        <div className="paginations mt-8">
            <ul className="unstyled">
                <li className="arrow">
                    <button onClick={() => onPageChange(currentPage - 1)} className="h4 fw-600 mb-0">
                        <i className="far fa-chevron-left"></i>
                    </button>
                </li>
                {pageNumbers.map((num) => (
                    <li key={num} className={currentPage === num ? 'active' : ''}>
                        <button onClick={() => onPageChange(num)} className="h6 fw-600 mb-0">
                            {num}
                        </button>
                    </li>
                ))}
                <li className="arrow">
                    <button onClick={() => onPageChange(currentPage + 1)} className="h4 fw-600 mb-0">
                        <i className="far fa-chevron-right"></i>
                    </button>
                </li>
            </ul>
        </div>
    );
};


const FlightList = ({ flights, flightsPerPage }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [expandedIndex, setExpandedIndex] = useState(null);

    const toggleExpand = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    const indexOfLastFlight = currentPage * flightsPerPage;
    const indexOfFirstFlight = indexOfLastFlight - flightsPerPage;
    const currentFlights = flights.slice(indexOfFirstFlight, indexOfLastFlight);

    const totalPages = Math.ceil(flights.length / flightsPerPage);

    return (
        <div>
            {currentFlights.map((flight, index) => (
                <FlightItem
                    key={index}
                    flight={flight}
                    index={index}
                    toggleExpand={toggleExpand}
                    expanded={expandedIndex === index}
                />
            ))}
            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
        </div>
    );
};


const Flight_listing = () => {

    const [activeTabManage, setActiveTabManage] = useState('manageBooking');
    const [reference, setReference] = useState('');
    const [lastName, setLastName] = useState('');

    const handleManageBookingTab = (tabId) => {
        setActiveTabManage(tabId);
    };

    const handleManageBooking = (e) => {
        e.preventDefault();
        console.log("Form Submitted:", { reference, lastName });
    };

    const [activeTabStatus, setActiveTabStatus] = useState('byRoute');
    const [fromStatus, setFromStatus] = useState('');
    const [toStatus, setToStatus] = useState('');
    const [dateStatus, setDateStatus] = useState('');

    const handleTabClickStatus = (tabId) => {
        setActiveTabStatus(tabId);
    };

    const handleSubmitStatus = (e) => {
        e.preventDefault();
        console.log("Form Submitted:", { reference, lastName });
    };

    const [tripType, setTripType] = useState('');
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [departDate, setDepartDate] = useState('');
    const [returnDate, setReturnDate] = useState('');
    const [passengers, setPassengers] = useState({ adult: 0, child: 0, infant: 0 });
    const [travelClass, setTravelClass] = useState('');
    const [stopOption, setStopOption] = useState('');
    const [daysInDubai, setDaysInDubai] = useState(0);

    const [activeTab, setActiveTab] = useState("flight");
    const [flightType, setFlightType] = useState("one-way");
    const [bookingDetails, setBookingDetails] = useState({
        depFrom: "",
        arrivalTo: "",
        depDate: "",
        returnDate: "",
        promoCode: ""
    });

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        if (tab == 'status') {
            handleTabClickStatus('byRoute');
        } else if (tab == "manage") {
            handleManageBookingTab('manageBooking')
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBookingDetails({ ...bookingDetails, [name]: value });
    };

    const handleFlightTypeChange = (e) => {
        setFlightType(e.target.value);
    };



    // flight listing api //
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            const response = await post(AIR_IQ_SEARCH, { data: {} }, true);
            const data = await response.json();
            if (data.status == false) {
                // toast.error(data.message);
                console.log('success');
            } else {
                console.log('faild');
                // toast.success(data.message);
            }

        } catch (error) {
            console.error(error);
            // toast.error('Error updating profile. Please try again.');
        }

        // console.log(bookingDetails);
        console.log("Booking details:", bookingDetails, flightType, passengers, travelClass);
    };

    const handlePassengerChange = (type, action) => {
        setPassengers(prevPassengers => {
            const count = prevPassengers[type];
            const updatedCount = action === 'increment' ? count + 1 : count - 1;
            return { ...prevPassengers, [type]: Math.max(0, updatedCount) }; // Prevent negative values
        });
    };

    const [showPopup, setShowPopup] = useState(false);
    const togglePopup = () => {
        setShowPopup(!showPopup); // Toggle the popup state
    };

    const handleSubmits = (e) => {
        e.preventDefault();
        const formData = {
            tripType,
            from,
            to,
            departDate,
            returnDate,
            passengers,
            travelClass,
            stopOption,
            daysInDubai,
        };
        console.log('Form Submitted', formData);
    };

    const [isCabinExpanded, setCabinExpanded] = useState(true);
    const [isPriceExpanded, setPriceExpanded] = useState(true);
    const [isAirlinesExpanded, setAirlinesExpanded] = useState(true);
    const [isStopsExpanded, setStopsExpanded] = useState(true);
    const [isDepartureExpanded, setDepartureExpanded] = useState(true);

    const [showMoreAirlines, setShowMoreAirlines] = useState(false);

    // Toggle the Show More / Show Less functionality
    const toggleShowMore = () => {
        setShowMoreAirlines(!showMoreAirlines);
    };


    return (
        <div className='flight-listing'>
            <TitleBanner />
            <section className="booking mb-60">
                <div className="container-fluid">
                    <div className="content">
                        <div className="card">
                            <div className="card-header">
                                <ul className="nav nav-tabs card-header-tabs">
                                    <li className="nav-item flight-sec">
                                        <button
                                            className={`cus-btn primary-light primary ${activeTab === "flight" ? "active" : ""}`}
                                            onClick={() => handleTabClick("flight")}
                                        >
                                            Flight
                                        </button>
                                    </li>
                                    {/* <li className="nav-item flight-sec">
                                    <button
                                        className={`cus-btn primary-light primary ${activeTab === "stopover" ? "active" : ""}`}
                                        onClick={() => handleTabClick("stopover")}
                                    >
                                        Stopover
                                    </button>
                                </li> */}
                                    <li className="nav-item flight-sec">
                                        <button
                                            className={`cus-btn primary-light primary ${activeTab === "manage" ? "active" : ""}`}
                                            onClick={() => handleTabClick("manage")}
                                        >
                                            Manage Booking
                                        </button>
                                    </li>
                                    <li className="nav-item flight-sec">
                                        <button
                                            className={`cus-btn primary-light primary ${activeTab === "status" ? "active" : ""}`}
                                            onClick={() => handleTabClick("status")}
                                        >
                                            Flight Status
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div className="card-body tab-content">
                                {activeTab === "flight" && (
                                    <form onSubmit={handleSubmit}>
                                        <div className="custom-control mb-32">
                                            <div className="radio-button">
                                                <input type="radio" name="way" className="custom-control-input" id="one" value="one-way" checked={flightType === "one-way"} onChange={handleFlightTypeChange} />
                                                <label className="custom-control-label" htmlFor="one">
                                                    One way
                                                </label>
                                            </div>
                                            <div className="radio-button">
                                                <input type="radio" name="way" className="custom-control-input" id="round" value="round-trip" checked={flightType === "round-trip"} onChange={handleFlightTypeChange} />
                                                <label className="custom-control-label" htmlFor="round">
                                                    Round-trip
                                                </label>
                                            </div>
                                            <div className="radio-button">
                                                <input type="radio" name="way" className="custom-control-input" id="multi" value="multi-city" checked={flightType === "multi-city"} onChange={handleFlightTypeChange} />
                                                <label className="custom-control-label" htmlFor="multi">
                                                    Multi-City
                                                </label>
                                            </div>
                                        </div>
                                        <div className="booking-info mb-32">
                                            <div className="d-xl-flex align-items-center gap-24 d-lg-block d-flex">
                                                <div className="custom-sel-input-block">
                                                    <label htmlFor="depFrom" className="h6 color-medium-gray">
                                                        From
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="sel-input auto-input"
                                                        id="depFrom"
                                                        name="depFrom"
                                                        value={bookingDetails.depFrom}
                                                        onChange={handleInputChange}
                                                        placeholder="From"
                                                    />
                                                </div>
                                                <div className="arrows">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <g clipPath="url(#clip0_518_2277)">
                                                            <path
                                                                d="M23.6804 6.07409L18.2259 0.619583C17.7999 0.193537 17.1092 0.193537 16.6831 0.619583C16.2571 1.04563 16.2571 1.73628 16.6831 2.16233L20.2754 5.75464H1.09096C0.488472 5.75464 3.51626e-05 6.24307 3.51626e-05 6.84556C3.51626e-05 7.44804 0.488472 7.93648 1.09096 7.93648H20.2754L16.6832 11.5287C16.2571 11.9548 16.2571 12.6455 16.6832 13.0715C16.8961 13.2845 17.1753 13.391 17.4545 13.391C17.7337 13.391 18.0129 13.2845 18.2258 13.0714L23.6804 7.61688C24.1064 7.19084 24.1064 6.50013 23.6804 6.07409Z"
                                                                fill="#ff7701"
                                                            />
                                                            <path
                                                                d="M22.9091 16.6637H3.72462L7.31683 13.0714C7.74288 12.6453 7.74288 11.9547 7.31683 11.5286C6.89088 11.1026 6.20013 11.1026 5.77409 11.5286L0.319535 16.9831C-0.106512 17.4092 -0.106512 18.0998 0.319535 18.5259L5.77404 23.9804C5.98713 24.1935 6.26627 24.3 6.54546 24.3C6.82465 24.3 7.10388 24.1935 7.31679 23.9804C7.74283 23.5544 7.74283 22.8637 7.31679 22.4377L3.72457 18.8455H22.9091C23.5116 18.8455 24 18.357 24 17.7546C24 17.1521 23.5116 16.6637 22.9091 16.6637Z"
                                                                fill="#ff7701"
                                                            />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_518_2277">
                                                                <rect width="24" height="24" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </div>

                                                <div className="custom-sel-input-block">
                                                    <label htmlFor="arrivalTo" className="h6 color-medium-gray">
                                                        To
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="sel-input auto-input"
                                                        id="arrivalTo"
                                                        name="arrivalTo"
                                                        value={bookingDetails.arrivalTo}
                                                        onChange={handleInputChange}
                                                        placeholder="To"
                                                    />
                                                </div>
                                            </div>
                                            <div className="d-xl-flex align-items-center gap-24">
                                                <div className="input-date-picker">
                                                    <label htmlFor="dep" className="h6 color-medium-gray">
                                                        Departure
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className="sel-input date_from"
                                                        id="dep"
                                                        name="depDate"
                                                        value={bookingDetails.depDate}
                                                        onChange={handleInputChange}
                                                        placeholder="Aug 18, 2023"
                                                    />
                                                </div>
                                                <div className="input-date-picker">
                                                    <label htmlFor="arrival" className="h6 color-medium-gray">
                                                        Returning
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className="sel-input date_to"
                                                        id="arrival"
                                                        name="returnDate"
                                                        value={bookingDetails.returnDate}
                                                        onChange={handleInputChange}
                                                        placeholder="Aug 20, 2023"
                                                    />
                                                </div>
                                            </div>
                                            <div className="custom-sel-input-block">
                                                <div className="h6 color-medium-gray">Passengers and Class</div>
                                                <div className="seat-booking color-black" onClick={togglePopup}>
                                                    <span className="total-passenger">
                                                        {passengers.adult + passengers.child + passengers.infant} Passengers
                                                    </span>
                                                    <span className="pasenger-class">{travelClass}</span>
                                                </div>

                                                {showPopup && (

                                                    <div className="passenger-area pessenger-box bg-white light-shadow br-5 p-24" style={{ display: 'block' }}>
                                                        <h4 className='color-black mb-32'>Passengers</h4>
                                                        <div className='passenger-box mb-24'>
                                                            <div className="row">
                                                                <div className="col-md-7 col-sm-6 col-6">
                                                                    <div className="content-box">
                                                                        <h5 className="lightest-black">Adult</h5>
                                                                        <p className="color-medium-gray light"> Above 12 years.</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-5 col-sm-6 col-6">
                                                                    <div className="quantity quantity-wrap">
                                                                        <button className='decrement' type="button" onClick={() => handlePassengerChange('adult', 'decrement')}>-</button>
                                                                        {/* <input className='number' type="text" value={passengers.adult} readOnly /> */}
                                                                        <input className='number' type="text" value={passengers.adult} readOnly />
                                                                        <button className='increment' type="button" onClick={() => handlePassengerChange('adult', 'increment')}>+</button>
                                                                        {/* <input className="decrement" type="button" value="-">
                                                                    <input type="text" name="quantity" value="0" maxlength="2" size="1" id="adult" className="number">
                                                                        <input className="increment" type="button" value="+">
                                                                        </div>
                                                                    </div> */}
                                                                    </div>
                                                                    {/* <span>Adult</span>
                                                            <button type="button" onClick={() => handlePassengerChange('adult', 'decrement')}>-</button>
                                                            <input type="text" value={passengers.adult} readOnly />
                                                            <button type="button" onClick={() => handlePassengerChange('adult', 'increment')}>+</button> */}

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='passenger-box mb-24'>
                                                            <div className="row">
                                                                <div className="col-md-7 col-sm-6 col-6">
                                                                    <div className="content-box">
                                                                        <h5 className="lightest-black">Child</h5>
                                                                        <p className="color-medium-gray light"> 2-11 years on travel date.</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-5 col-sm-6 col-6">
                                                                    <div className="quantity quantity-wrap">
                                                                        <button className='decrement' type="button" onClick={() => handlePassengerChange('child', 'decrement')}>-</button>
                                                                        <input className='number' type="text" value={passengers.child} readOnly />
                                                                        <button className='increment' type="button" onClick={() => handlePassengerChange('child', 'increment')}>+</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='passenger-box mb-24'>
                                                            <div className="row">
                                                                <div className="col-md-7 col-sm-6 col-6">
                                                                    <div className="content-box">
                                                                        <h5 className="lightest-black">Infant</h5>
                                                                        <p className="color-medium-gray light"> Below 2 years.</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-5 col-sm-6 col-6">
                                                                    <div className="quantity quantity-wrap">
                                                                        <button className='decrement' type="button" onClick={() => handlePassengerChange('infant', 'decrement')}>-</button>
                                                                        <input className='number' type="text" value={passengers.infant} readOnly />
                                                                        <button className='increment' type="button" onClick={() => handlePassengerChange('infant', 'increment')}>+</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p className="color-medium-gray light mb-32">Please note: You can book for a maximum of nine passengers.</p>
                                                        <h4 className="color-black mb-32">Travel Class</h4>
                                                        <div className="radio-tile-group">
                                                            <div className="input-container">
                                                                {/* <input id="economy" className="radio-button" type="radio" name="radio" value="Economy" /> */}
                                                                <input className="radio-button" type="radio" name="class" id="economy" onChange={() => setTravelClass('Economy')} />
                                                                <div className="radio-tile">
                                                                    <label className="radio-tile-label" htmlFor="economy">Economy</label>
                                                                    {/* <label for="economy" className="radio-tile-label">Economy</label> */}
                                                                </div>
                                                            </div>
                                                            <div className="input-container">
                                                                <input className="radio-button" type="radio" name="class" id="business" onChange={() => setTravelClass('Business')} />
                                                                <div className="radio-tile">
                                                                    <label className="radio-tile-label" htmlFor="business">Business</label>
                                                                </div>
                                                            </div>
                                                            <div className="input-container">
                                                                <input className="radio-button" type="radio" name="class" id="firstClass" onChange={() => setTravelClass('First Class')} />
                                                                <div className="radio-tile">
                                                                    <label className="radio-tile-label" htmlFor="firstClass">First Class</label>
                                                                </div>
                                                            </div>
                                                            <div className="input-container">
                                                                <input id="pre-eco" className="radio-button" type="radio" name="radio" value="Premium Economy" />
                                                                <div className="radio-tile">
                                                                    <label for="pre-eco" className="radio-tile-label">Premium
                                                                        Economy</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}



                                            </div>
                                        </div>
                                        <div className="row d-flex justify-content-end">
                                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-9">
                                                <div className="row align-items-center">
                                                    <div className="col-sm-6">
                                                        <div className="booking-info promo-code mb-sm-0 mb-16">
                                                            <div className="custom-sel-input-block m-0">
                                                                <input
                                                                    type="text"
                                                                    className="sel-input p-0"
                                                                    id="promoCode"
                                                                    name="promoCode"
                                                                    value={bookingDetails.promoCode}
                                                                    onChange={handleInputChange}
                                                                    placeholder="Enter Promo Code"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 d-flex justify-content-end">
                                                        <button type="submit" className="cus-btn">
                                                            Show Flight
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none">
                                                                <g clipPath="url(#clip0_502_1331)">
                                                                    <path d="M31.6933 0.544584C30.6572 -0.491824 27.1402 1.34503 26.1041 2.38143L21.9545 6.53127H3.07887C2.63024 6.53127 2.24462 6.85011 2.16055 7.29104C2.07669 7.73189 2.31798 8.16995 2.73524 8.3348L15.2174 13.2677L7.5633 20.9216H0.323909C0.168651 20.9221 0.0346723 21.0323 0.00576263 21.1852C-0.023357 21.3385 0.060152 21.4901 0.20498 21.5471L6.29687 23.9548C6.33201 24.1078 6.38108 24.2574 6.44394 24.4038L6.17745 24.6709C5.79778 25.0503 5.79778 25.6651 6.17745 26.045C6.55664 26.4247 7.17263 26.4247 7.55182 26.045L7.81194 25.785C7.95935 25.8501 8.11132 25.9014 8.26623 25.9382L10.6144 31.9561C10.6709 32.1013 10.8229 32.1851 10.976 32.1568C11.0419 32.145 11.1002 32.1123 11.1451 32.0673C11.2044 32.0087 11.2399 31.9274 11.2399 31.8382V24.7512L19.0155 16.976L23.9019 29.4993C24.0654 29.9177 24.5037 30.1608 24.9452 30.0781C25.136 30.0421 25.3038 29.9498 25.4333 29.8212C25.6038 29.6499 25.7071 29.4151 25.7077 29.1591V10.284L29.8573 6.13423C30.893 5.09789 32.7293 1.58085 31.6933 0.544584Z" fill="white" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_502_1391">
                                                                        <rect width="32" height="32" fill="black" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                )}

                                {activeTab === "stopover" && (
                                    <form onSubmit={handleSubmits}>
                                        <div className="custom-control mb-32">
                                            <div className="radio-button">
                                                <input type="radio" name="way" id="one-way" onChange={() => setTripType('one-way')} />
                                                <label htmlFor="one-way">One way</label>
                                            </div>
                                            <div className="radio-button">
                                                <input type="radio" name="way" id="round-trip" onChange={() => setTripType('round-trip')} />
                                                <label htmlFor="round-trip">Round-trip</label>
                                            </div>
                                            <div className="radio-button">
                                                <input type="radio" name="way" id="multi-city" onChange={() => setTripType('multi-city')} />
                                                <label htmlFor="multi-city">Multi-City</label>
                                            </div>
                                        </div>

                                        <div className="booking-info mb-32">
                                            <div className="d-xl-flex align-items-center gap-24 d-lg-block d-flex">
                                                <div className="custom-sel-input-block">
                                                    <label htmlFor="flightFrom" className="h6 color-medium-gray">
                                                        From
                                                    </label>
                                                    <input className='sel-input auto-input'
                                                        type="text"
                                                        id="flightFrom"
                                                        value={from}
                                                        onChange={(e) => setFrom(e.target.value)}
                                                        placeholder="From"
                                                    />
                                                </div>
                                                <div className="arrows">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <g clipPath="url(#clip0_518_2277)">
                                                            <path
                                                                d="M23.6804 6.07409L18.2259 0.619583C17.7999 0.193537 17.1092 0.193537 16.6831 0.619583C16.2571 1.04563 16.2571 1.73628 16.6831 2.16233L20.2754 5.75464H1.09096C0.488472 5.75464 3.51626e-05 6.24307 3.51626e-05 6.84556C3.51626e-05 7.44804 0.488472 7.93648 1.09096 7.93648H20.2754L16.6832 11.5287C16.2571 11.9548 16.2571 12.6455 16.6832 13.0715C16.8961 13.2845 17.1753 13.391 17.4545 13.391C17.7337 13.391 18.0129 13.2845 18.2258 13.0714L23.6804 7.61688C24.1064 7.19084 24.1064 6.50013 23.6804 6.07409Z"
                                                                fill="#ff7701"
                                                            />
                                                            <path
                                                                d="M22.9091 16.6637H3.72462L7.31683 13.0714C7.74288 12.6453 7.74288 11.9547 7.31683 11.5286C6.89088 11.1026 6.20013 11.1026 5.77409 11.5286L0.319535 16.9831C-0.106512 17.4092 -0.106512 18.0998 0.319535 18.5259L5.77404 23.9804C5.98713 24.1935 6.26627 24.3 6.54546 24.3C6.82465 24.3 7.10388 24.1935 7.31679 23.9804C7.74283 23.5544 7.74283 22.8637 7.31679 22.4377L3.72457 18.8455H22.9091C23.5116 18.8455 24 18.357 24 17.7546C24 17.1521 23.5116 16.6637 22.9091 16.6637Z"
                                                                fill="#ff7701"
                                                            />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_518_2277">
                                                                <rect width="24" height="24" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </div>

                                                <div className="custom-sel-input-block">
                                                    <label htmlFor="flightTo" className="h6 color-medium-gray">
                                                        To
                                                    </label>
                                                    <input className='sel-input auto-input'
                                                        type="text"
                                                        id="flightTo"
                                                        value={to}
                                                        onChange={(e) => setTo(e.target.value)}
                                                        placeholder="To"
                                                    />
                                                </div>
                                            </div>
                                            <div className="d-xl-flex align-items-center gap-24">
                                                <div className="input-date-picker">
                                                    <label htmlFor="departDate" class="h6 color-medium-gray">Departing</label>
                                                    <input className="sel-input date_from"
                                                        type="text"
                                                        id="departDate"
                                                        value={departDate}
                                                        onChange={(e) => setDepartDate(e.target.value)}
                                                        placeholder="Select Date"
                                                    />
                                                </div>
                                                <div className="input-date-picker">
                                                    <label htmlFor="returnDate" class="h6 color-medium-gray">Returning</label>
                                                    <input className="sel-input date_from"
                                                        type="text"
                                                        id="returnDate"
                                                        value={returnDate}
                                                        onChange={(e) => setReturnDate(e.target.value)}
                                                        placeholder="Select Date"
                                                    />
                                                </div>
                                                <div className="custom-sel-input-block">
                                                    <div className="h6 color-medium-gray">Passengers and Class</div>
                                                    <div className="seat-booking color-black">
                                                        <span className="total-pasenger">{passengers}</span> Passengers /{" "}
                                                        <span className="pasenger-class">{travelClass}</span> Business{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 mb-md-0 mb-24">
                                                <h5 className="color-medium-gray mb-24">When would you like to
                                                    add a stop in dubai during your journey?</h5>
                                                <div className="custom-control ">
                                                    <div className=" radio-button">
                                                        <input type="radio" name="stopOption" id="departureStop" onChange={() => setStopOption('Departure')} />
                                                        <label htmlFor="departureStop">Departure</label>
                                                        {/* <input type="radio" name="flight" className="custom-control-input" id="departure" />
                                                    <label className="custom-control-label" for="departure">Departure</label> */}
                                                    </div>
                                                    <div className=" radio-button">
                                                        <input type="radio" name="stopOption" id="returnStop" onChange={() => setStopOption('Return')} />
                                                        <label htmlFor="returnStop">Return</label>
                                                        {/* <input type="radio" name="flight" className="custom-control-input" id="return" />
                                                    <label className="custom-control-label" for="return">Return</label> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-xl-5 col-lg-5 offset-lg-0 offset-md-2 col-md-5 offset-sm-2 col-sm-5'>
                                                <h5 className='color-medium-gray mb-24'>How many days would you
                                                    like to stay in Dubai?</h5>
                                                <div className='quantity quantity-wrap'>
                                                    <button className='decrement' type="button" onClick={() => setDaysInDubai(daysInDubai - 1)}>-</button>
                                                    <input className='number' type="text" value={daysInDubai} readOnly />
                                                    <button className='increment' type="button" onClick={() => setDaysInDubai(daysInDubai + 1)}>+</button>
                                                </div>
                                            </div>
                                            <div className='col-xl-2 d-flex align-items-center'>
                                                <button type="submit" className="cus-btn">Show Flight
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none">
                                                        <g clipPath="url(#clip0_502_1331)">
                                                            <path d="M31.6933 0.544584C30.6572 -0.491824 27.1402 1.34503 26.1041 2.38143L21.9545 6.53127H3.07887C2.63024 6.53127 2.24462 6.85011 2.16055 7.29104C2.07669 7.73189 2.31798 8.16995 2.73524 8.3348L15.2174 13.2677L7.5633 20.9216H0.323909C0.168651 20.9221 0.0346723 21.0323 0.00576263 21.1852C-0.023357 21.3385 0.060152 21.4901 0.20498 21.5471L6.29687 23.9548C6.33201 24.1078 6.38108 24.2574 6.44394 24.4038L6.17745 24.6709C5.79778 25.0503 5.79778 25.6651 6.17745 26.045C6.55664 26.4247 7.17263 26.4247 7.55182 26.045L7.81194 25.785C7.95935 25.8501 8.11132 25.9014 8.26623 25.9382L10.6144 31.9561C10.6709 32.1013 10.8229 32.1851 10.976 32.1568C11.0419 32.145 11.1002 32.1123 11.1451 32.0673C11.2044 32.0087 11.2399 31.9274 11.2399 31.8382V24.7512L19.0155 16.976L23.9019 29.4993C24.0654 29.9177 24.5037 30.1608 24.9452 30.0781C25.136 30.0421 25.3038 29.9498 25.4333 29.8212C25.6038 29.6499 25.7071 29.4151 25.7077 29.1591V10.284L29.8573 6.13423C30.893 5.09789 32.7293 1.58085 31.6933 0.544584Z" fill="white" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_502_1391">
                                                                <rect width="32" height="32" fill="black" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>


                                        {/* <div className="row d-flex justify-content-end">
                                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-9">
                                            <div className="row align-items-center">
                                                <div className="col-sm-6">
                                                    <div className="booking-info promo-code mb-sm-0 mb-16">
                                                        <div className="custom-sel-input-block m-0">
                                                            <input
                                                                type="text"
                                                                className="sel-input p-0"
                                                                id="promoCode"
                                                                name="promoCode"
                                                                value={bookingDetails.promoCode}
                                                                onChange={handleInputChange}
                                                                placeholder="Enter Promo Code"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 d-flex justify-content-end">
                                                    <button type="submit" className="cus-btn">
                                                        Show Flight
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}


                                    </form>
                                )}

                                {activeTab === "manage" && (
                                    <div className="tab-pane active" id="manage">

                                        <div className="booking-bar">
                                            <ul className="nav nav-tabs" data-bs-tabs="tabs">
                                                <li className="nav-item">
                                                    <button type="button" className={`nav-link ${activeTabManage === 'manageBooking' ? 'active' : ''}`}
                                                        // aria-current={activeTabManage === 'manageBooking' ? 'true' : 'false'}
                                                        onClick={() => handleManageBookingTab('manageBooking')}>
                                                        Manage Booking
                                                    </button>
                                                </li>
                                                <li className="nav-item">
                                                    <button type="button" className={`nav-link ${activeTabManage === 'checkIn' ? 'active' : ''}`}
                                                        // aria-current={activeTabManage === 'checkIn' ? 'true' : 'false'}
                                                        onClick={() => handleManageBookingTab('checkIn')} > Check In
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="card-body tab-content">

                                            {activeTabManage === "manageBooking" && (
                                                <div className='tab-pane active' id="manageBooking">
                                                    <form onSubmit={handleManageBooking}>
                                                        <div className="row align-items-center">
                                                            <div className="col-xl-10 col-lg-10 col-md-10">
                                                                <div className="booking-info d-block">
                                                                    <div className="row">
                                                                        <div className="col-lg-6 col-md-6 col-sm-6">
                                                                            <div className="custom-sel-input-block v-2">
                                                                                <input type="text" className="sel-input" name="reference" id="ref-name"
                                                                                    placeholder="Booking Reference" value={reference} onChange={(e) => setReference(e.target.value)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6 col-md-6 col-sm-6">
                                                                            <div className="custom-sel-input-block m-0">
                                                                                <input type="text" className="sel-input" name="name" id="last-name" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-2 col-md-2 mt-md-0 mt-32">
                                                                <button type="submit" className="cus-btn">
                                                                    Check Booking
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            )}

                                            {activeTabManage === "checkIn" && (
                                                <div className='tab-pane active' id="checkIn">
                                                    <form onSubmit={handleManageBooking}>
                                                        <div className="row align-items-center">
                                                            <div className="col-xl-10 col-lg-10 col-md-10">
                                                                <div className="booking-info d-block">
                                                                    <div className="row">
                                                                        <div className="col-lg-6 col-md-6 col-sm-6">
                                                                            <div className="custom-sel-input-block v-2 m-0">
                                                                                <input type="text" className="sel-input" name="reference" id="referName" placeholder="Booking Reference Deepak" value={reference} onChange={(e) => setReference(e.target.value)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6 col-md-6 col-sm-6">
                                                                            <div className="custom-sel-input-block m-0">
                                                                                <input type="text" className="sel-input" name="name" id="lastName" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-2 col-md-2 mt-md-0 mt-32">
                                                                <button type="submit" className="cus-btn">
                                                                    Check Booking
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}


                                {activeTab === "status" && (
                                    <div className="tab-pane active" id="status">

                                        <div className="booking-bar">
                                            <ul className="nav nav-tabs" data-bs-tabs="tabs">
                                                <li className="nav-item">
                                                    <button type="button" className={`nav-link ${activeTabStatus === 'byRoute' ? 'active' : ''}`}
                                                        onClick={() => handleTabClickStatus('byRoute')}>
                                                        By Routes
                                                    </button>
                                                </li>
                                                <li className="nav-item">
                                                    <button type="button" className={`nav-link ${activeTabStatus === 'flightNo' ? 'active' : ''}`}
                                                        onClick={() => handleTabClickStatus('flightNo')} > By Flight Number
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="card-body tab-content">

                                            {activeTabStatus === "byRoute" && (
                                                <div className='tab-pane active' id="byRoute">
                                                    <form onSubmit={handleSubmitStatus}>
                                                        <div className="row align-items-center">
                                                            <div className="col-xl-10 col-lg-10">
                                                                <div className="booking-info v-2 d-md-flex d-inline-block justify-content-between">
                                                                    <div className="custom-sel-input-block m-0">
                                                                        <input
                                                                            type="text"
                                                                            className="sel-input auto-input ui-autocomplete-input"
                                                                            id="flightDepart"
                                                                            placeholder="From"
                                                                            value={fromStatus}
                                                                            onChange={(e) => setFromStatus(e.target.value)} />
                                                                        <div className="slector-wrapper"></div>
                                                                    </div>
                                                                    <div className="custom-sel-input-block m-0 d-flex flex-row align-items-center">
                                                                        <div className="vertical-line d-md-flex d-none"></div>
                                                                        <input
                                                                            type="text"
                                                                            className="sel-input auto-input ui-autocomplete-input"
                                                                            id="flightArrive"
                                                                            placeholder="To"
                                                                            value={toStatus}
                                                                            onChange={(e) => setToStatus(e.target.value)}
                                                                        />
                                                                        <div className="slector-wrapper"></div>
                                                                    </div>
                                                                    <div>
                                                                        <div className="input-date-picker date-time m-0">
                                                                            <input
                                                                                type="text"
                                                                                className="sel-input date_from picker__input"
                                                                                id="flightTimeDate"
                                                                                placeholder="Aug 20, 2023"
                                                                                value={dateStatus}
                                                                                onChange={(e) => setDateStatus(e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-2 col-md-2 mt-md-0 mt-32">
                                                                <button type="submit" className="cus-btn">
                                                                    Show Flight <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none">
                                                                        <g clipPath="url(#clip0_502_1331)">
                                                                            <path d="M31.6933 0.544584C30.6572 -0.491824 27.1402 1.34503 26.1041 2.38143L21.9545 6.53127H3.07887C2.63024 6.53127 2.24462 6.85011 2.16055 7.29104C2.07669 7.73189 2.31798 8.16995 2.73524 8.3348L15.2174 13.2677L7.5633 20.9216H0.323909C0.168651 20.9221 0.0346723 21.0323 0.00576263 21.1852C-0.023357 21.3385 0.060152 21.4901 0.20498 21.5471L6.29687 23.9548C6.33201 24.1078 6.38108 24.2574 6.44394 24.4038L6.17745 24.6709C5.79778 25.0503 5.79778 25.6651 6.17745 26.045C6.55664 26.4247 7.17263 26.4247 7.55182 26.045L7.81194 25.785C7.95935 25.8501 8.11132 25.9014 8.26623 25.9382L10.6144 31.9561C10.6709 32.1013 10.8229 32.1851 10.976 32.1568C11.0419 32.145 11.1002 32.1123 11.1451 32.0673C11.2044 32.0087 11.2399 31.9274 11.2399 31.8382V24.7512L19.0155 16.976L23.9019 29.4993C24.0654 29.9177 24.5037 30.1608 24.9452 30.0781C25.136 30.0421 25.3038 29.9498 25.4333 29.8212C25.6038 29.6499 25.7071 29.4151 25.7077 29.1591V10.284L29.8573 6.13423C30.893 5.09789 32.7293 1.58085 31.6933 0.544584Z" fill="white" />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_502_1391">
                                                                                <rect width="32" height="32" fill="black" />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            )}

                                            {activeTabStatus === "flightNo" && (
                                                <div className='tab-pane active' id="flightNo">
                                                    <form onSubmit={handleSubmitStatus}>
                                                        <div className="row align-items-center">
                                                            <div className="col-xl-10 col-lg-10">
                                                                <div className="booking-info v-2 d-md-flex d-inline-block justify-content-between">
                                                                    <div className="custom-sel-input-block m-0">
                                                                        <input
                                                                            type="text"
                                                                            className="sel-input auto-input ui-autocomplete-input"
                                                                            id="flightDepart"
                                                                            placeholder="From Chintu"
                                                                            value={fromStatus}
                                                                            onChange={(e) => setFromStatus(e.target.value)}
                                                                        />
                                                                        <div className="slector-wrapper"></div>
                                                                    </div>
                                                                    <div className="custom-sel-input-block m-0 d-flex flex-row align-items-center">
                                                                        <div className="vertical-line d-md-flex d-none"></div>
                                                                        <input
                                                                            type="text"
                                                                            className="sel-input auto-input ui-autocomplete-input"
                                                                            id="flightArrive"
                                                                            placeholder="To Chintu"
                                                                            value={toStatus}
                                                                            onChange={(e) => setToStatus(e.target.value)}
                                                                        />
                                                                        <div className="slector-wrapper"></div>
                                                                    </div>
                                                                    <div>
                                                                        <div className="input-date-picker date-time m-0">
                                                                            <input
                                                                                type="text"
                                                                                className="sel-input date_from picker__input"
                                                                                id="flightTimeDate"
                                                                                placeholder="Aug 20, 2023"
                                                                                value={dateStatus}
                                                                                onChange={(e) => setDateStatus(e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-2 col-md-2 mt-md-0 mt-32">
                                                                <button type="submit" className="cus-btn">
                                                                    Show Flight <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none">
                                                                        <g clipPath="url(#clip0_502_1331)">
                                                                            <path d="M31.6933 0.544584C30.6572 -0.491824 27.1402 1.34503 26.1041 2.38143L21.9545 6.53127H3.07887C2.63024 6.53127 2.24462 6.85011 2.16055 7.29104C2.07669 7.73189 2.31798 8.16995 2.73524 8.3348L15.2174 13.2677L7.5633 20.9216H0.323909C0.168651 20.9221 0.0346723 21.0323 0.00576263 21.1852C-0.023357 21.3385 0.060152 21.4901 0.20498 21.5471L6.29687 23.9548C6.33201 24.1078 6.38108 24.2574 6.44394 24.4038L6.17745 24.6709C5.79778 25.0503 5.79778 25.6651 6.17745 26.045C6.55664 26.4247 7.17263 26.4247 7.55182 26.045L7.81194 25.785C7.95935 25.8501 8.11132 25.9014 8.26623 25.9382L10.6144 31.9561C10.6709 32.1013 10.8229 32.1851 10.976 32.1568C11.0419 32.145 11.1002 32.1123 11.1451 32.0673C11.2044 32.0087 11.2399 31.9274 11.2399 31.8382V24.7512L19.0155 16.976L23.9019 29.4993C24.0654 29.9177 24.5037 30.1608 24.9452 30.0781C25.136 30.0421 25.3038 29.9498 25.4333 29.8212C25.6038 29.6499 25.7071 29.4151 25.7077 29.1591V10.284L29.8573 6.13423C30.893 5.09789 32.7293 1.58085 31.6933 0.544584Z" fill="white" />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_502_1391">
                                                                                <rect width="32" height="32" fill="black" />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <section className="flight-listing-page mb-60">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 mb-xl-0 mb-32">
                            <div className="sidebar bg-white br-10 light-shadow">
                                <div className="sidebar-title">
                                    <h4 className="lightest-black">Filter Search</h4>
                                </div>

                                {/* Cabin Class Filter */}
                                <div className="filter-block p-24 box-1">
                                    <div
                                        className="title mb-32 d-flex justify-content-between align-items-center"
                                        onClick={() => setCabinExpanded(!isCabinExpanded)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <h4 className="color-black">Cabin</h4>
                                        <i className={`fal fa-chevron-${isCabinExpanded ? 'up' : 'down'} color-primary`}></i>
                                    </div>
                                    {isCabinExpanded && (
                                        <div className="content-block mb-32">
                                            <div className="radio-tile-group sidebar">
                                                {['Economy', 'Business', 'First Class', 'Premium Economy'].map((classType) => (
                                                    <div className="input-container" key={classType}>
                                                        <input id={classType.replace(/\s/g, '').toLowerCase()} className="radio-button" type="radio" name="radio" value={classType} />
                                                        <div className="radio-tile sidebar-radio">
                                                            <label htmlFor={classType.replace(/\s/g, '').toLowerCase()} className="radio-tile-label sidebar-label">{classType} Class</label>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    <hr className="bg-sec-gray mb-8" />
                                </div>

                                {/* Price Range Filter */}
                                <div className="filter-block plr-24 box-2">
                                    <div
                                        className="title mb-32 d-flex justify-content-between align-items-center"
                                        onClick={() => setPriceExpanded(!isPriceExpanded)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <h4 className="color-black">Price Range</h4>
                                        <i className={`fal fa-chevron-${isPriceExpanded ? 'up' : 'down'} color-primary`}></i>
                                    </div>
                                    {isPriceExpanded && (
                                        <div className="content-block">
                                            <div className="slider-track mb-32">
                                                <div className="d-flex justify-content-between mb-4">
                                                    <h5>$100</h5>
                                                    <h5>$3000</h5>
                                                </div>
                                                <input type="text" className="js-slider form-control" defaultValue="0" />
                                            </div>
                                        </div>
                                    )}
                                    <hr className="bg-sec-gray mb-32" />
                                </div>

                                {/* Airlines Filter */}
                                <div className="filter-block plr-24 box-3">
                                    <div
                                        className="title mb-32 d-flex justify-content-between align-items-center"
                                        onClick={() => setAirlinesExpanded(!isAirlinesExpanded)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <h4 className="color-black fw-500">Airlines</h4>
                                        <i className={`fal fa-chevron-${isAirlinesExpanded ? 'up' : 'down'} color-primary`}></i>
                                    </div>
                                    {isAirlinesExpanded && (
                                        <div className="content-block">
                                            {['Emirates Airline', 'Qatar Airways', 'Saudi Arabian Airlines', 'Air Asia'].map((airline, index) => (
                                                <div className="custom-control mb-32" key={index}>
                                                    <div className="d-flex justify-content-between align-items-center mb-24">
                                                        <div className="radio-button">
                                                            <input type="radio" name="airline" className="custom-control-input" id={airline.replace(/\s/g, '').toLowerCase()} />
                                                            <label className="custom-control-label lightest-black" htmlFor={airline.replace(/\s/g, '').toLowerCase()}>{airline}</label>
                                                        </div>
                                                        <h5 className="light-black">({index + 2})</h5>
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="filter-block-2 box-6">
                                                {/* Show More/Show Less Button */}
                                                <div
                                                    className="title justify-content-start load-more-btn h5 mb-24 color-primary"
                                                    onClick={toggleShowMore}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    {showMoreAirlines ? 'Show less airlines' : 'Show all airlines'}&nbsp;&nbsp;
                                                    <i className={`fas fal fa-caret-${showMoreAirlines ? 'up' : 'down'}`}></i>
                                                </div>

                                                {/* Additional Airlines - Only shown when showMoreAirlines is true */}
                                                {showMoreAirlines && (
                                                    <div className="content-block">
                                                        <div className="d-flex justify-content-between align-items-center mb-24">
                                                            <div className="radio-button">
                                                                <input type="radio" name="airline" className="custom-control-input" id="saudi" />
                                                                <label className="custom-control-label lightest-black" htmlFor="saudi">Saudi Arabian Airlines</label>
                                                            </div>
                                                            <h5 className="light-black">(06)</h5>
                                                        </div>
                                                        <div className="d-flex justify-content-between align-items-center mb-24">
                                                            <div className="radio-button">
                                                                <input type="radio" name="airline" className="custom-control-input" id="air_asia" />
                                                                <label className="custom-control-label lightest-black" htmlFor="air_asia">Air Asia</label>
                                                            </div>
                                                            <h5 className="light-black">(08)</h5>
                                                        </div>
                                                        <div className="d-flex justify-content-between align-items-center mb-24">
                                                            <div className="radio-button">
                                                                <input type="radio" name="airline" className="custom-control-input" id="pia-air" />
                                                                <label className="custom-control-label lightest-black" htmlFor="pia-air">Pakistan International Airline</label>
                                                            </div>
                                                            <h5 className="light-black">(10)</h5>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    <hr className="bg-sec-gray mb-32" />
                                </div>

                                {/* Stops Filter */}
                                <div className="filter-block plr-24 box-4">
                                    <div
                                        className="title mb-32 d-flex justify-content-between align-items-center"
                                        onClick={() => setStopsExpanded(!isStopsExpanded)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <h4 className="color-black">Stops</h4>
                                        <i className={`fal fa-chevron-${isStopsExpanded ? 'up' : 'down'} color-primary`}></i>
                                    </div>
                                    {isStopsExpanded && (
                                        <div className="content-block mb-32">
                                            <div className="radio-tile-group sidebar mb-24">
                                                {['Any', 'Non-Stop', '1 Stop', '2 Stops'].map((stopType) => (
                                                    <div className="input-container" key={stopType}>
                                                        <input id={stopType.replace(/\s/g, '').toLowerCase()} className="radio-button" type="radio" name="radio" value={stopType} />
                                                        <div className="radio-tile sidebar-radio">
                                                            <label htmlFor={stopType.replace(/\s/g, '').toLowerCase()} className="radio-tile-label sidebar-label">{stopType}</label>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="filter-checkbox">
                                                <input type="checkbox" id="night" />
                                                <label htmlFor="night" className="allow_over">Allow overnight stopovers</label>
                                            </div>
                                        </div>
                                    )}
                                    <hr className="bg-sec-gray mb-32" />
                                </div>

                                {/* Departure Filter */}
                                <div className="filter-block plr-24 box-5">
                                    <div
                                        className="title mb-16 pb-4 d-flex justify-content-between align-items-center"
                                        onClick={() => setDepartureExpanded(!isDepartureExpanded)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <h4 className="color-black">Departure</h4>
                                        <i className={`fal fa-chevron-${isDepartureExpanded ? 'up' : 'down'} color-primary`}></i>
                                    </div>
                                    {isDepartureExpanded && (
                                        <div className="content-block">
                                            <div className="radio-tile-group sidebar pb-24">
                                                {[
                                                    { id: 'e-morning', label: 'Early Morning', time: '4:00am - 5:59am' },
                                                    { id: 'morning', label: 'Morning', time: '6:00am - 11:59am' },
                                                    { id: 'afternoon', label: 'Afternoon', time: '12:00pm - 4:59pm' },
                                                    { id: 'evening', label: 'Evening', time: '5:00pm - 7:59pm' },
                                                ].map(({ id, label, time }) => (
                                                    <div className="input-container" key={id}>
                                                        <input id={id} className="radio-button" type="radio" name="clock-time" value={id} />
                                                        <div className="radio-tile sidebar-departure-radio">
                                                            <i className="fal fa-clock"></i>
                                                            <label htmlFor={id} className="radio-tile-label departure-radio">{label}</label>
                                                            <h6>{time}</h6>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-8">
                            <FlightList flights={flightsData} flightsPerPage={8} />
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}


export default Flight_listing