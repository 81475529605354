import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import '../footer/footer.css'


const Footer = () => {
    return (
        <footer className="bg-white p-40">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-lg-0 mb-32">
                        <Link to="/">
                            <img src="https://flight.readytouse.in/assets/media/logo.png" alt="Vivan Logo" className="mb-16" />
                        </Link>
                        <p className="dark-gray mb-16">
                            Lorem ipsum dolor sit amet consectetur. Aliquet vulputate augue penatibus in libero et id aliquam. In ridiculus pretium est velit euismod.
                        </p>
                        {/* <h6 className="lightest-black mb-8">Subscribe to our special offers</h6>
                        <form method="post" action="/subscribe">
                            <input type="email" className="form-control" placeholder="Email address" name="email" required />
                            <button type="submit" className="subscribe">Subscribe</button>
                        </form> */}
                    </div>

                    <div className="col-xl-2 col-lg-2 col-md-6 col-6 mb-lg-0 mb-32">
                        <h4 className="light-black mb-24">Useful Links</h4>
                        <ul className="unstyled">
                            {/* <li className="mb-16"><Link to="/" className="light-black">Home</Link></li>
                            <li className="mb-16"><Link to="/about-us" className="light-black">About</Link></li>
                            <li className="mb-16"><Link to="/Contact-us" className="light-black">Contact Us</Link></li>
                            <li className="mb-16"><Link to="/privacy-policy" className="light-black">Privacy Policy</Link></li> */}
                            <li className="mb-16"><NavLink exact to="/" activeClassName="active">Home</NavLink></li>
                            <li className="mb-16"><NavLink to="/flight-listing" activeClassName="active">Flight Listing</NavLink></li>
                            <li className="mb-16"><NavLink to="/about-us" activeClassName="active">About</NavLink></li>
                            <li className="mb-16"><NavLink to="/Contact-us" activeClassName="active">Contact</NavLink></li>
                            <li className="mb-16"><NavLink to="/privacy-policy" activeClassName="active">Privacy Policy</NavLink></li>
                        </ul>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-6 col-6 mb-md-0 mb-32">
                        <h4 className="light-black mb-24">Manage</h4>
                        <ul className="unstyled">
                            <li className="mb-16"><Link to="/flight-booking" className="light-black">Check-in</Link></li>
                            <li className="mb-16"><Link to="/flight-listing" className="light-black">Flight Status</Link></li>
                        </ul>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                        <h4 className="light-black mb-16">Contact Us</h4>
                        <ul className="unstyled">
                            <li className="mb-8">
                                <img src="https://flight.readytouse.in/assets/media/icons/location-bk.png" alt="Location Icon" />
                                &nbsp;&nbsp;Jaipur, Rajasthan, India.
                            </li>
                            <li className="mb-8 h4 color-primary">
                                <img src="https://flight.readytouse.in/assets/media/icons/telephone.png" alt="Phone Icon" />
                                &nbsp;&nbsp;+91 88244 15793
                            </li>
                            <li className="mb-24">
                                <a href="mailto:email@example.com">
                                    <img src="https://flight.readytouse.in/assets/media/icons/mail.png" alt="Email Icon" />
                                    &nbsp;&nbsp;email@example.com
                                </a>
                            </li>
                        </ul>
                        <div className="social-link mb-32">
                            <h6 className="light-black mb-8">Follow Us!</h6>
                            <ul className="unstyled">
                                <li><a href="#" className="active"><img src="https://flight.readytouse.in/assets/media/icons/instagram.png" alt="Instagram" /></a></li>
                                <li><a href="#"><img src="https://flight.readytouse.in/assets/media/icons/facebook.png" alt="Facebook" /></a></li>
                                <li><a href="#"><img src="https://flight.readytouse.in/assets/media/icons/linkedin.png" alt="LinkedIn" /></a></li>
                                <li><a href="#"><img src="https://flight.readytouse.in/assets/media/icons/twitter.png" alt="Twitter" /></a></li>
                            </ul>
                        </div>
                        <p className="light-black">©2024 SIT Aeroaway All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

