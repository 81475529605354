import React, { useState } from 'react';
import '../../component/login-signin/login-sign.css';
import { Link,useNavigate } from 'react-router-dom';
import { post } from "../../API/apiHelper";
import { account_login } from "../../API/endpoints";
import { toast ,ToastContainer} from 'react-toastify';
const Login = () => {

    const navigate = useNavigate();
    const [mobileNumber, setMobileNumber] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const response = await post(
            account_login, {
            email: mobileNumber,
            password: password,
        }, true);

        const data = await response.json();
        if (data.status == false) {
            toast.error(data.message);
        } else {
            localStorage.setItem("authtoken", JSON.stringify(data.data.token));
            sessionStorage.setItem('userData', JSON.stringify(data.data)); 
            navigate("/user/profile-main");
            toast.success(data.message);
            window.location.reload();
        }
    };

    return (
        <section className="signup bg-white">
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

            <div className="row align-items-center justify-content-center">
                <div className="col-lg-5 col-md-9 col-sm-10 p-0">
                    <div className="container-fluid">
                        <div className="form-block">
                            <Link to="/" className="color-primary h6 mb-30">
                                <i className="fal fa-chevron-left"></i>&nbsp;&nbsp;Back To Home
                            </Link>
                            <h2 className="mb-30 light-black">Log in</h2>
                            <h6 className="mb-24 text-center">Sign in with your Email</h6>

                            <form onSubmit={handleSubmit} className="form-group contact-form">
                                <div className="row">

                                    <div className="col-sm-12">
                                        <div className="mb-24">
                                            <input
                                                type="tel"
                                                className="form-control"
                                                id="phone"
                                                name="mnumber"
                                                value={mobileNumber}
                                                onChange={(e) => setMobileNumber(e.target.value)}
                                                required
                                                placeholder="Registered Email"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-12">
                                        <div className="mb-24">
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="create-password"
                                                name="password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                                placeholder="Password"
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button type="submit" className="cus-btn small-pad mb-24">Login</button>
                                    </div>
                                </div>
                            </form>
                            {errorMessage && <div className="alert-msg">{errorMessage}</div>}
                        </div>
                    </div>
                </div>

                <div className="col-lg-7 p-0">
                    <div className="img-block">
                        <img src="https://flight.readytouse.in/assets/media/images/login.jpg" alt="Login" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Login;
