import React from 'react';
import '../flight-booking-main/flight-booking-main.css'

const FlightBookingDetails = () => {
    const bookingDetail = {
        departureTime: '12:00',
        departureAirport: 'DUB',
        arrivalTime: '12:50',
        arrivalAirport: 'SHJ',
        flightDuration: '0h 50m',
        stops: '1 Stop',
        departureDate: '14 Aug, 2023',
        arrivalDate: '14 Aug, 2023',
        airline: 'Feel Dubai Airlines',
        flightNumber: 'FK234',
        aircraft: 'BOEING 777-90',
        luggage: '25KG luggage free',
    };

    const priceSummary = [
        { description: 'Adult x 1', details: 'Lorem ipsum dolor sit amet consectetur.', price: 540 },
        { description: 'Room Service', details: 'Lorem ipsum dolor sit amet consectetur.', price: 50 },
        { description: 'Gym Fee', details: 'Lorem ipsum dolor sit amet consectetur.', price: 30 },
        { description: 'Service Charges', details: 'Lorem ipsum dolor sit amet consectetur.', price: 20 },
    ];

    const subtotal = priceSummary.reduce((acc, item) => acc + item.price, 0);
    const discount = 40;
    const total = subtotal - discount;

    return (
        <div className="col-xl-4 mb-lg-0 mb-32">
            <div className="flight-booking-detail light-shadow mb-32">
                <div className="flight-title">
                    <h4 className="color-black">Your Booking Detail</h4>
                </div>
                <div className="box bg-white p-24">
                    <div className="flight-detail mb-32">
                        <div className="flight-departure">
                            <h5 className="color-black">{bookingDetail.departureTime}</h5>
                            <h5 className="dark-gray text-end">{bookingDetail.departureAirport}</h5>
                        </div>
                        <div className="d-inline-flex align-items-center gap-8">
                            <span>From</span>
                            <div className="from-to text-center">
                                <h5 className="dark-gray">{bookingDetail.flightDuration}</h5>
                                <img className='route-plan' src="https://flight.readytouse.in/assets/media/icons/route-plan.png" alt="Route Plan" />
                                <h6 className="color-black">{bookingDetail.stops}</h6>
                            </div>
                            <span>To</span>
                        </div>
                        <div className="flight-departure">
                            <h5 className="color-black">{bookingDetail.arrivalTime}</h5>
                            <h5 className="dark-gray">{bookingDetail.arrivalAirport}</h5>
                        </div>
                    </div>
                    <div className="d-flex justify-content-around mb-32">
                        <div className="flight-departure">
                            <h6 className="dark-gray">Departure</h6>
                            <h5 className="color-black">{bookingDetail.departureDate}</h5>
                        </div>
                        <div className="vr-line"></div>
                        <div className="flight-departure">
                            <h6 className="dark-gray">Arrival</h6>
                            <h5 className="color-black">{bookingDetail.arrivalDate}</h5>
                        </div>
                    </div>
                    <hr className="bg-medium-gray mb-32" />
                    <div className="text">
                        <h6 className="color-medium-gray">Tpm Line</h6>
                        <h6 className="color-medium-gray">Operated by {bookingDetail.airline}</h6>
                        <h6 className="color-medium-gray">Economy | Flight {bookingDetail.flightNumber} | Aircraft {bookingDetail.aircraft}</h6>
                        <h6 className="color-medium-gray">Adult(s): {bookingDetail.luggage}</h6>
                    </div>
                </div>
            </div>
            <div className="flight-booking-detail light-shadow">
                <div className="flight-title">
                    <h4 className="color-black">Price Summary</h4>
                </div>
                <div className="box bg-white p-24">
                    {priceSummary.map((item, index) => (
                        <div key={index} className="d-flex align-items-center justify-content-between mb-24">
                            <div>
                                <h5 className="color-black">{item.description}</h5>
                                <p className="dark-gray">{item.details}</p>
                            </div>
                            <h5 className="color-black">${item.price}</h5>
                        </div>
                    ))}
                    <div className="d-flex align-items-center justify-content-between mb-24">
                        <div>
                            <h5 className="color-black">Sub Total</h5>
                            <p className="dark-gray">Lorem ipsum dolor sit amet consectetur.</p>
                        </div>
                        <h5 className="color-black">${subtotal}</h5>
                    </div>
                    <hr className="bg-medium-gray mb-24" />
                    <div className="d-flex align-items-center justify-content-between mb-24">
                        <div>
                            <h4 className="color-black">Deal / Discount</h4>
                        </div>
                        <h4 className="color-black">${discount}</h4>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <h4 className="color-black">Total</h4>
                        </div>
                        <h4 className="color-black">${total}</h4>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FlightBookingDetails;
