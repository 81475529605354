import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import './sidebarmenu.css'




// Components

const MainMenuList = () => (
    <div className="mobile-nav__container">
        <ul className="main-menu__list">
            <li className='current'><NavLink exact to="/" activeClassName="active">Home</NavLink></li>
            <li><NavLink to="/flight-listing" activeClassName="active">Flight Listing</NavLink></li>
            {/* <li><NavLink to="/flight-booking" activeClassName="active">Flight Booking</NavLink></li> */}
            <li><NavLink to="/about-us" activeClassName="active">About</NavLink></li>
            <li><NavLink to="/Contact-us" activeClassName="active">Contact</NavLink></li>


            {/* <li><a href="javascript:void(0);" class="expanded">Car
                <button aria-label="dropdown toggler" class="expanded">
                    <i class="fa fa-angle-down"></i>
                </button>
            </a>
                <ul class="sub-menu" style={{ display: 'block' }}>
                    <li><a href="car-listing.">Car Listing</a></li>
                    <li><a href="car-booking.">Car Booking</a></li>
                    <li><a href="car-detail.">Car Detail</a></li>
                </ul>
            </li> */}
        </ul>
    </div>
);


const CloseButton = ({ closeSNavbar }) =>
    <button href="#" className="main-menu__toggler mobile-nav__toggler" onClick={closeSNavbar}>
        <i className="fas fa-window-close"></i>
    </button>
    // <span className="mobile-nav__close mobile-nav__toggler" onClick={closeSNavbar}>X</span>
    ;

const LogoBox = () => (
    <div className="logo-box">
        <Link to="/" aria-label="logo image">
            <img src="assets/media/logo.png" alt="" className="invisible" />
        </Link>
    </div>
);

const MobileNavContainer = () => (
    <MainMenuList />
);

const MobileNavContact = () => (
    <ul className="mobile-nav__contact list-unstyled">
        <li>
            <i className="fas fa-envelope"></i>
            <a href="mailto:example@company.com">example@company.com</a>
        </li>
        <li>
            <i className="fa fa-phone-alt"></i>
            <a href="tel:+12345678">+123 (4567) -890</a>
        </li>
    </ul>
);

const MobileNavWrapper = ({ isSNavbarActive }) => (
    <div className={`mobile-nav__wrapper ${isSNavbarActive ? 'expanded' : ''}`}>
        <CloseButton />
        <div className="mobile-nav__overlay mobile-nav__toggler"></div>
        <div className="mobile-nav__content">
            <LogoBox />
            <MobileNavContainer />
            <MobileNavContact />
            {/* <MobileNavSocial /> */}
        </div>
    </div>
);

export default MobileNavWrapper;
