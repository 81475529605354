import React from 'react';
import { Link } from 'react-router-dom';
import '../visa/visa.css'

const VisaWidget = () => {
    return (

        <div className="d-flex flex-column justify-content-between gap-10">
            <h1 className="text-4xl font-semibold text-black">
                Guaranteed{' '}
                <span className="text-transparent underline cursor-pointer bg-text-gradient bg-clip-text decoration-purple-800 decoration-1 underline-offset-4">
                    visa on time
                </span>
                {' '}to
            </h1>
            <div className="d-flex flex-column gap-8 flex-sm-row">
                {/* Citizen of */}
                <div className="d-flex flex-row align-items-center flex-grow-1 px-4 bg-atcst rounded-3xl shadow-drop-modal">
                    <svg
                        fill="none"
                        height="26"
                        viewBox="0 0 26 26"
                        width="26"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-gray-500 cursor-pointer"
                    >
                        <path
                            d="M23.8334 18.4167V12.5117C23.8334 11.0997 23.8334 10.3937 23.6538 9.74184C23.4948 9.16438 23.2332 8.62019 22.8817 8.13523C22.4848 7.58781 21.9336 7.14678 20.831 6.26472L17.9976 3.99805C16.2141 2.57118 15.3223 1.85774 14.3332 1.58413C13.4609 1.34279 12.5393 1.34279 11.6669 1.58413C10.6779 1.85774 9.78611 2.57117 8.00253 3.99804L5.16919 6.26472C4.0666 7.14678 3.51531 7.58781 3.11849 8.13523C2.76695 8.62019 2.5054 9.16438 2.34632 9.74184C2.16675 10.3937 2.16675 11.0997 2.16675 12.5117V18.4167C2.16675 18.8036 2.16675 18.997 2.17745 19.1603C2.34156 21.6641 4.33595 23.6585 6.83973 23.8226C7.00307 23.8333 7.19652 23.8333 7.58342 23.8333C7.73817 23.8333 7.81555 23.8333 7.88089 23.8291C8.8824 23.7634 9.68016 22.9657 9.7458 21.9641C9.75008 21.8988 9.75008 21.8214 9.75008 21.6667V17.3333C9.75008 15.5383 11.2052 14.0833 13.0001 14.0833C14.795 14.0833 16.2501 15.5383 16.2501 17.3333V21.6667C16.2501 21.8214 16.2501 21.8988 16.2544 21.9641C16.32 22.9657 17.1178 23.7634 18.1193 23.8291C18.1846 23.8333 18.262 23.8333 18.4167 23.8333C18.8036 23.8333 18.9971 23.8333 19.1604 23.8226C21.6642 23.6585 23.6586 21.6641 23.8227 19.1603C23.8334 18.997 23.8334 18.8036 23.8334 18.4167Z"
                            stroke="#2F384C"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        ></path>
                    </svg>
                    <div className="d-flex flex-column flex-1 w-100">
                        <input className="form-control cstm-form-cntrl h-3.71rem border-gray-100 rounded-none shadow-none cursor-pointer" placeholder="Citizen of" aria-haspopup="listbox" aria-expanded="false" type="text" autoComplete="off" />
                    </div>
                </div>

                <div className="pt-2 pb-2">
                    <hr className="border-0 border-t border-gray-300" />
                </div>

                {/* Going to */}
                <div className="d-flex flex-row align-items-center flex-grow-1 px-4 bg-atcst rounded-3xl shadow-drop-modal">
                    <svg fill="none" height="24" viewBox="0 0 25 24" width="25" xmlns="http://www.w3.org/2000/svg" className="text-gray-500">
                        <path d="M7.59529 22.971L16.9678 13.9261H21.1244C21.7807 13.9261 22.4418 13.7856 23.0588 13.5085L23.463 13.3027C24.0062 13.0597 24.3428 12.5514 24.3428 11.9748C24.3428 11.3982 24.0062 10.8899 23.4862 10.6577L23.0364 10.4304C22.4418 10.1641 21.7818 10.0236 21.1244 10.0236H16.9682L7.59749 0.981502C7.12014 0.521042 6.48624 0.267334 5.81081 0.267334H4.6257C4.44086 0.267334 4.27108 0.364882 4.18288 0.521042C4.09395 0.677126 4.10021 0.868364 4.20126 1.0186L10.3069 10.2128C8.77747 10.3036 7.50049 10.4265 5.94642 10.5767L4.35376 10.7319L2.01513 7.31623C1.92032 7.17865 1.76268 7.0967 1.59253 7.0967H0.581596C0.429092 7.0967 0.283204 7.164 0.187292 7.27913C0.0902776 7.39523 0.053896 7.54644 0.0873366 7.68986L1.07512 11.9748L0.0873366 16.2597C0.0792521 16.2949 0.0752024 16.33 0.0752024 16.365C0.0752024 16.4754 0.113421 16.5826 0.186182 16.6704C0.283196 16.7856 0.429092 16.8528 0.581596 16.8528H1.59253C1.76268 16.8528 1.92105 16.7709 2.01439 16.6334L4.34972 13.2177L5.94715 13.3728C7.49938 13.5231 8.77527 13.646 10.3051 13.7367L4.20126 22.9309C4.10021 23.0823 4.09506 23.2723 4.18288 23.4275C4.27108 23.5845 4.44085 23.6823 4.62606 23.6823H5.81081C6.48624 23.6823 7.12014 23.4297 7.59529 22.971Z" fill="#2F384C"></path>
                    </svg>
                    <div className="d-flex flex-column flex-1 w-100">
                        <input className="form-control cstm-form-cntrl h-3.71rem border-gray-100 rounded-none shadow-none cursor-pointer" placeholder="Going to" aria-haspopup="listbox" aria-expanded="false" type="text" autoComplete="off" />
                    </div>
                </div>


            </div>

            <div className="d-flex flex-column gap-8 flex-sm-row">

                {/* Travel Date */}
                <div className="d-flex flex-row align-items-center flex-grow-1 px-4 bg-atcst rounded-3xl shadow-drop-modal">
                    <svg fill="none" height="22" viewBox="0 0 24 22" width="24" xmlns="http://www.w3.org/2000/svg" className="text-gray-500">
                        <path d="M4 3.33333V19.6667C4 20.4524 4.44772 21.0001 5.23341 21.0001H18.7666C19.5523 21.0001 20 20.4524 20 19.6667V3.33333C20 2.54762 19.5523 2.00001 18.7666 2.00001H5.23341C4.44772 2.00001 4 2.54762 4 3.33333Z" stroke="#2F384C" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"></path>
                        <path d="M9 0.999996V4.33333" stroke="#2F384C" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"></path>
                        <path d="M15 0.999996V4.33333" stroke="#2F384C" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"></path>
                    </svg>
                    <div className="d-flex flex-column flex-1 w-100">
                        <input className="form-control cstm-form-cntrl h-3.71rem border-gray-100 rounded-none shadow-none cursor-pointer"
                            placeholder="Travel Date" type="date" autoComplete="off" />
                    </div>
                </div>

                <div className="d-flex flex-row align-items-center flex-grow-1 px-4 bg-atcst rounded-3xl shadow-drop-modal">
                    <svg fill="none" height="22" viewBox="0 0 24 22" width="24" xmlns="http://www.w3.org/2000/svg" className="text-gray-500">
                        <path d="M4 3.33333V19.6667C4 20.4524 4.44772 21.0001 5.23341 21.0001H18.7666C19.5523 21.0001 20 20.4524 20 19.6667V3.33333C20 2.54762 19.5523 2.00001 18.7666 2.00001H5.23341C4.44772 2.00001 4 2.54762 4 3.33333Z" stroke="#2F384C" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"></path>
                        <path d="M9 0.999996V4.33333" stroke="#2F384C" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"></path>
                        <path d="M15 0.999996V4.33333" stroke="#2F384C" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"></path>
                    </svg>
                    <div className="d-flex flex-column flex-1 w-100">
                        <input className="form-control cstm-form-cntrl h-3.71rem border-gray-100 rounded-none shadow-none cursor-pointer"
                            placeholder="Return Date" type="date" autoComplete="off" />
                    </div>
                </div>

            </div>
            {/* <div class="d-flex justify-content-end"><button type="submit" class="cus-btn">Search</button><div id="message" class="alert-msg"></div></div> */}
            <div class="d-flex justify-content-end"><Link to="/Visas" class="cus-btn">Search</Link><div id="message" class="alert-msg"></div></div>
        </div>
    );
};

export default VisaWidget;
