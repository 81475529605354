import React, { useState } from "react";
import ProfileSidebarWidget from '../profile-sidebar'
import { ToastContainer, toast } from "react-toastify";
import VisafFor from '../visa/visa-f'
import VisaList from '../visa/visa-list/visa-list'

import '../visa/visa.css'



const VisaApplication = () => {
    return (
        <section className="pt-3 pb-5" style={{ minHeight: 'calc(100vh - 436px)' }}>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className="container">
                <div className="row">
                    <ProfileSidebarWidget />
                    <div className="col-xl-9 col-lg-8">
                        <div className="d-grid mb-0 d-lg-none w-100">
                            <button type="button" className="mb-4 items-center justify-content-center gap-1 btn btn-primary menu-btnspt">
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M496 384H160v-16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h80v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h336c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm0-160h-80v-16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h336v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h80c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm0-160H288V48c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16C7.2 64 0 71.2 0 80v32c0 8.8 7.2 16 16 16h208v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h208c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16z"></path>
                                </svg> Menu
                            </button>
                        </div>
                        <div className="vstack gap-4">
                            <div className="border card">
                                <div className="border-bottom card-header">
                                    <h3 className="card-header-title">Visa Apply</h3>
                                </div>
                                <div className="card-body p-0">
                                    <div className="position-relative max-w-screen-lg rounded-br-5 p-5">
                                        <VisafFor />
                                    </div>
                                </div>
                                <div className="card-body p-0 visa-list">
                                    <VisaList />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </section >
    );
};

export default VisaApplication;







