import React from "react";
import '../travel-us/travel-us.css'
import { Link } from "react-router-dom";

const TravelUs = () => {
    return (
        <div className="travel-sec mb-40">
            <div className="cloud-vector-block">
                <img src="https://flight.readytouse.in/assets/media/vector/cloud-vector.png" alt="cloud" className="cloud-vector" />
            </div>
            <img src="https://flight.readytouse.in/assets/media/vector/vector-line.png" alt="line vector" className="line-vector" />
            <div className="container-fluid">
                <div className="row align-items-center justify-content-center row-gap-sm-5 row-gap-4">
                    <div
                        className="col-xxl-3 col-lg-4 col-md-8">
                        <div className="left-content">
                            <img src="https://flight.readytouse.in/assets/media/logo.png" alt="logo" className="mb-40" />
                            <div className="text mb-40">
                                <span className="h1 review-block bg-lightest-gray">TRAVEL</span>{" "}
                                <span className="h1 review-block bg-lightest-gray">All</span>{" "}
                                <span className="h1 bg-lightest-gray">OVER</span>{" "}
                                <span className="h1 bg-lightest-gray color-primary">The</span>{" "}
                                <span className="h1 bg-lightest-gray color-primary">WORLD</span>
                            </div>
                            <Link to="/flight-listing" className="cus-btn">
                                Booking Now
                            </Link>
                        </div>
                    </div>
                    <div className="offset-xxl-1 col-xxl-8 col-lg-8 col-md-10">
                        <div className="right-images-block">
                            <img src="https://flight.readytouse.in/assets/media/vector/border-line.png" alt="border line" className="border-image" />
                            <div className="row justify-content-center align-items-center">
                                <div className="col-lg-3 col-md-3 col-3">
                                    <img src="https://flight.readytouse.in/assets/media/images/paris.png" alt="Paris" className="side-image" />
                                </div>
                                <div className="col-lg-5 col-md-5 col-5">
                                    <img src="https://flight.readytouse.in/assets/media/images/dubai.png" alt="Dubai" className="center-image" />
                                </div>
                                <div className="col-lg-3 col-md-3 col-3">
                                    <img src="https://flight.readytouse.in/assets/media/images/italy.png" alt="Italy" className="side-image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TravelUs;
