import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../flight-booking-main/flight-booking-main.css'


const FlightBookingForm = () => {
    const [formData, setFormData] = useState({
        gender: '',
        firstName: '',
        lastName: '',
        email: '',
        nationality: '',
        phone: '',
        dateOfBirth: '',
        postalCode: '',
        flightNumber: '',
        saveDetails: false,
        mealType: '',
        wheelchair: '',
        paymentTime: '',
        paymentMethod: '',
        cardHolderName: '',
        cardNumber: '',
        expiryDate: '',
        cvc: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCheckboxChange = (e) => {
        setFormData({ ...formData, saveDetails: e.target.checked });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form data:', formData);
        // Add your form submission logic here
    };



    const [saveDetails, setSaveDetails] = useState(false);

    const handleCheckboxChanges = () => {
        setSaveDetails(!saveDetails);
    };

    const [mealType, setMealType] = useState('');
    const [wheelchairRequest, setWheelchairRequest] = useState('');

    const handleMealChange = (event) => {
        setMealType(event.target.value);
    };

    const handleWheelchairChange = (event) => {
        setWheelchairRequest(event.target.value);
    };



    return (
        <div className="col-xl-8">
            <div className="booking-form">
                <form onSubmit={handleSubmit} id="flight-form" method="post" role="form" className="contact-form form-wizard">
                    <div className="form-wizard-header">
                        <ul className="nav list-unstyled form-wizard-steps clearfix">
                            <li className="nav-item activated">
                                <button className="nav-link">
                                    <span className="number">1</span><i className="fal fa-check"></i>
                                </button>
                                <h5 className="color-black">Your Selection</h5>
                            </li>
                            <li className="nav-item active">
                                <button className="nav-link">
                                    <span className="number">2</span><i className="fal fa-check"></i>
                                </button>
                                <h5 className="color-black">Your Details</h5>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link">
                                    <span className="number">3</span><i className="fal fa-check"></i>
                                </button>
                                <h5 className="color-black">Final Step</h5>
                            </li>
                        </ul>
                    </div>
                    <div className="wizard-content overflow-visible mb-24">
                        <fieldset id="step-2" className="tab-pane show wizard-fieldset p-0">
                            <div className="detail-form mb-32">
                                <h4 className="black p-0 mb-40">Enter Your Details</h4>
                                <div className="row ">
                                    <div className="col-sm-3 mb-40">
                                        <div className="gender-select">
                                            <div className="select">
                                                <select name="gender" value={formData.gender} onChange={handleInputChange} className="wizard-required">
                                                    <option selected disabled>Gender</option>
                                                    <option value="male">Male</option>
                                                    <option value="female">Female</option>
                                                    <option value="other">Other</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-5 mb-40">
                                        <input
                                            type="text"
                                            className="form-control wizard-required"
                                            id="first-name"
                                            name="firstName"
                                            value={formData.firstName}
                                            onChange={handleInputChange}
                                            placeholder="First Name"
                                        />
                                    </div>
                                    <div className="col-sm-4 mb-40">
                                        <input
                                            type="text"
                                            className="form-control wizard-required"
                                            id="last-name"
                                            name="lastName"
                                            value={formData.lastName}
                                            onChange={handleInputChange}
                                            placeholder="Last Name"
                                        />
                                    </div>
                                    <div className="col-sm-6 mb-40">
                                        <input
                                            type="email"
                                            className="form-control wizard-required"
                                            id="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            placeholder="Email"
                                        />
                                    </div>
                                    <div className="col-sm-6 mb-40">
                                        <div className="gender-select">
                                            <div className="select">
                                                <select name="nationality" value={formData.nationality} onChange={handleInputChange} className="wizard-required">
                                                    <option selected disabled>Nationality</option>
                                                    <option value="american">American</option>
                                                    <option value="british">British</option>
                                                    <option value="indian">Indian</option>
                                                    <option value="canadian">Canadian</option>
                                                    <option value="pakistani">Pakistani</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mb-40">
                                        <input
                                            type="tel"
                                            className="form-control wizard-required"
                                            id="phone"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleInputChange}
                                            placeholder="Your Number"
                                        />
                                    </div>
                                    <div className="col-sm-6 mb-40">
                                        <input
                                            type="text"
                                            className="sel-input date_from form-control wizard-required"
                                            id="flightDep"
                                            name="dateOfBirth"
                                            value={formData.dateOfBirth}
                                            onChange={handleInputChange}
                                            placeholder="Date of Birth"
                                        />
                                    </div>
                                    <div className="col-sm-6 mb-sm-0 mb-40">
                                        <input
                                            type="number"
                                            className="form-control wizard-required"
                                            id="post_code"
                                            name="postalCode"
                                            value={formData.postalCode}
                                            onChange={handleInputChange}
                                            placeholder="Postal Code"
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <input
                                            type="number"
                                            className="form-control wizard-required"
                                            id="f_number"
                                            name="flightNumber"
                                            value={formData.flightNumber}
                                            onChange={handleInputChange}
                                            placeholder="Flight Number"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="detail-form term_conditions mb-32">
                                <h4 className="black p-0 mb-8">Save your details!</h4>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <p className="dark-gray mb-24 fw-400">
                                            Use your contact details to create an account and speed up future bookings. (We won't save your payment information.)
                                        </p>
                                        <div className="filter-checkbox mb-24">
                                            <input
                                                type="checkbox"
                                                id="save"
                                                className="wizard-required"
                                                checked={saveDetails}
                                                onChange={handleCheckboxChanges}
                                            />
                                            <label htmlFor="save" className="dark-gray fw-400">
                                                Save my details so I can book faster next time.
                                            </label>
                                        </div>
                                        <p className="dark-gray fw-400">
                                            By signing in or creating an account, you agree with our
                                            <Link to="/privacy-policy" className="color-primary">Terms &amp; conditions</Link>
                                        </p>
                                        <div className="wizard-form-error"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="detail-form extra-item p-24 mb-32">
                                <h4 className="black p-0 mb-24">Extras</h4>
                                <div className="col-sm-12 mb-24">
                                    <div className="select-1">
                                        <select
                                            name="mealType"
                                            id="format"
                                            className="wizard-required"
                                            value={mealType}
                                            onChange={handleMealChange}
                                        >
                                            <option value="" disabled>Select Meal Type</option>
                                            <option value="fast">Fast Food</option>
                                            <option value="vegetarian">Vegetarian</option>
                                            <option value="non">Non-Veg</option>
                                        </select>
                                        <div className="wizard-form-error"></div>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="select-2">
                                        <select
                                            name="wheelchairRequest"
                                            id="format-2"
                                            className="wizard-required"
                                            value={wheelchairRequest}
                                            onChange={handleWheelchairChange}
                                        >
                                            <option value="" disabled>Request Wheelchair</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                        <div className="wizard-form-error"></div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset id="step-3" className="tab-pane wizard-fieldset p-0">
                            <div className="detail-form mb-32">
                                <h4 className="black p-0 mb-24">When would you like to pay?</h4>
                                <div className="radio-button mb-24">
                                    <input
                                        type="radio"
                                        name="paymentTime"
                                        value="later"
                                        checked={formData.paymentTime === 'later'}
                                        onChange={handleInputChange}
                                    />
                                    <label>Pay Later</label>
                                </div>
                                <div className="radio-button">
                                    <input
                                        type="radio"
                                        name="paymentTime"
                                        value="now"
                                        checked={formData.paymentTime === 'now'}
                                        onChange={handleInputChange}
                                    />
                                    <label>Pay Now</label>
                                </div>
                            </div>
                            <div className="detail-form v-2">
                                <h4 className="black p-0 mb-24">How would you like to pay?</h4>
                                <div className="radio-tile-group sidebar d-block">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="input-container">
                                                <input
                                                    type="radio"
                                                    name="paymentMethod"
                                                    value="newCard"
                                                    checked={formData.paymentMethod === 'newCard'}
                                                    onChange={handleInputChange}
                                                />
                                                <label>New Card</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="input-container">
                                                <input
                                                    type="radio"
                                                    name="paymentMethod"
                                                    value="savedCard"
                                                    checked={formData.paymentMethod === 'savedCard'}
                                                    onChange={handleInputChange}
                                                />
                                                <label>Saved Card</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-24">
                                    <input
                                        type="text"
                                        className="form-control wizard-required"
                                        name="cardHolderName"
                                        value={formData.cardHolderName}
                                        onChange={handleInputChange}
                                        placeholder="Card Holder's Name"
                                    />
                                </div>
                                <div className="col-sm-6 mb-24">
                                    <input
                                        type="text"
                                        className="form-control wizard-required"
                                        name="cardNumber"
                                        value={formData.cardNumber}
                                        onChange={handleInputChange}
                                        placeholder="Card Number"
                                    />
                                </div>
                                <div className="col-sm-4 mb-24">
                                    <input
                                        type="text"
                                        className="form-control wizard-required"
                                        name="expiryDate"
                                        value={formData.expiryDate}
                                        onChange={handleInputChange}
                                        placeholder="MM/YY"
                                    />
                                </div>
                                <div className="col-sm-2 mb-24">
                                    <input
                                        type="text"
                                        className="form-control wizard-required"
                                        name="cvc"
                                        value={formData.cvc}
                                        onChange={handleInputChange}
                                        placeholder="CVC"
                                    />
                                </div>
                            </div>
                        </fieldset>
                        <div className="d-flex gap-24">
                            <a
                                href="javascript:;"
                                className="form-wizard-next-btn cus-btn w-100"
                            // onClick={ } 
                            >
                                Next
                            </a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default FlightBookingForm;
