import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
// import './ProfileSidebarWidget.css';  
import { get } from "../../API/apiHelper";
import { users_profile ,IMAGE_BASE_URL} from "../../API/endpoints";
import profileimage from "../../assets/images/profile.png";
const ProfileSidebarWidget = () => {
    const [userData, setUserData] = useState(null);
    const [profilePreview, setProfilePreview] = useState(profileimage); // Image preview state

    useEffect(() => {
        const userDataFromSession = sessionStorage.getItem('userData');
        if (userDataFromSession && userDataFromSession !== null) {
            const userData = JSON.parse(userDataFromSession);
            setUserData(userData.model);
        }
    }, []);


    const [formData, setFormData] = useState(null);
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await get(users_profile, true);
                if (!response.ok) {
                    const errorMsg = await response.text();
                    throw new Error(`Error ${response.status}: ${errorMsg}`);
                }
                const data = await response.json();

                setFormData(data.data);
                setProfilePreview( `${IMAGE_BASE_URL}${data.data.profile_photo}`  || profileimage);

            } catch (error) {
                console.error('Failed to fetch user data:', error);
            }
        };
        fetchUserData();
    }, []);

    return (
        <div className="col-xl-3 col-lg-4">
            <div className="d-none d-lg-block">
                <div className="bg-light w-100 card">
                    <div className="position-absolute top-0 end-0 p-3">
                        <span>
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path>
                                <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"></path>
                            </svg>
                        </span>
                    </div>
                    <div className="p-3 card-body">
                        <div className="text-center mb-3">
                            <div className="avatar avatar-xl mb-2">
                                <img
                                    src={profilePreview}
                                    className="avatar-img rounded-circle border border-2 border-white"
                                    alt="User Avatar"
                                />
                            </div>
                            <h4 className="mb-0">{formData?.name || 'Guest'}</h4>
                            <NavLink className="text-reset text-primary-hover small" to="/user/profile-main">
                                {formData?.email || 'guest@example.com'}
                            </NavLink>
                            <hr className="bg-light-gray mt-16 mb-16" />
                        </div>
                        <ul className="nav nav-pills-primary-soft flex-column">


                            <li className="nav-item">
                                <NavLink className="nav-link items-center"
                                    to="/user/profile-main"
                                    activeClassName="active"
                                >
                                    <i className="fas fa-user fa-fw me-2"></i>
                                    My Profile
                                </NavLink>
                            </li>


                            <li className="nav-item">
                                <NavLink
                                    className="nav-link items-center"
                                    to="/user/my-bookings"
                                    activeClassName="active"
                                >
                                    <i className="fas fa-bookmark fa-fw me-2"></i>
                                    My Bookings
                                </NavLink>
                            </li>


                            <li className="nav-item">
                                <NavLink
                                    className="nav-link items-center"
                                    to="/user/wallet-history"
                                    activeClassName="active">
                                    <i className="fas fa-users fa-fw me-2"></i>
                                    Wallet History
                                </NavLink>
                            </li>


                            <li className="nav-item">
                                <NavLink className="nav-link items-center"
                                    to="/user/visa"
                                    activeClassName="active">
                                    <i className="fas fa-users fa-fw me-2"></i>
                                    Visa
                                </NavLink>
                            </li>
                            {/* <li className="nav-item">
                                <NavLink
                                    className="nav-link items-center"
                                    to="/user/travelers"
                                    activeClassName="active"
                                >
                                    <i className="fas fa-users fa-fw me-2"></i>
                                    My Travelers
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    className="nav-link items-center"
                                    to="/user/invoices"
                                    activeClassName="active">
                                    <i className="fas fa-file-invoice fa-fw me-2"></i>
                                    My Invoices
                                </NavLink>
                            </li> */}
                        
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileSidebarWidget;
