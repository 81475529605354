import React from 'react';
import '../benefits/benefits.css'; // Assuming a CSS file for styles

const benefitsData = [
    {
        imgSrc: 'https://flight.readytouse.in/assets/media/vector/benefit-1.png',
        title: 'We are Now Available',
        description: 'Call +91 88244 15793 contact with us',
    },
    {
        imgSrc: 'https://flight.readytouse.in/assets/media/vector/benefit-2.png',
        title: 'International Flight',
        description: 'Call +91 88244 15793 contact with us',
    },
    {
        imgSrc: 'https://flight.readytouse.in/assets/media/vector/benefit-3.png',
        title: 'Check Refund',
        description: 'Call +91 88244 15793 contact with us',
    },
];

const Benefit = () => {
    return (
        <section className="benefit p-40 sal-animate" data-sal="slide-up" data-sal-duration="800" data-sal-delay="100" data-sal-easing="ease-in-out">
            <div className="container-fluid">
                <div className="row">
                    {benefitsData.map((benefit, index) => (
                        <div key={index} className="col-xl-4 col-lg-6 col-md-6 mb-xl-0 mb-24">
                            <div className="benefit-block bg-white">
                                <div className="image-box">
                                    <img src={benefit.imgSrc} alt={benefit.title} />
                                </div>
                                <div className="text-box">
                                    <h4 className="lightest-black mb-8">{benefit.title}</h4>
                                    <p className="color-medium-gray">{benefit.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Benefit;
