import React from 'react'
import '../flight-booking-main/flight-booking-main.css'
import FlightBookingMainLeft from '../flight-booking-main/flight-booking-main-left'
import FlightBookingMainRight from '../flight-booking-main/flight-booking-main-right'

const FlightBookingMain = () => {
    return (
        <section className="flight-booking">
            <div className="container-fluid">
                <div className="row">
                    <FlightBookingMainLeft />
                    <FlightBookingMainRight />
                    {/* <div className="col-xl-8">
                    </div>
                    <div className="col-xl-4 mb-lg-0 mb-32">
                    </div> */}
                </div>
            </div>
        </section>
    );
};

export default FlightBookingMain;