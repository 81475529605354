import React, { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import '../nav-profile/nav-profile.css'
import {  toast } from "react-toastify";
import { post ,get} from "../../API/apiHelper";
import { account_logout, users_profile,IMAGE_BASE_URL } from "../../API/endpoints";
import profileimage from "../../assets/images/profile.png";

const ProfileDropdownWidget = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [formData, setFormData] = useState(null);
    const [profilePreview, setProfilePreview] = useState(profileimage); // Image preview state

    useEffect(() => {
        const userDataFromSession = sessionStorage.getItem('userData');
        if (userDataFromSession && userDataFromSession != null) {
            const userData = JSON.parse(userDataFromSession);
            setUserData(userData.model);
        }
    }, []);

    const handleLogout = async () => {
        const confirmLogout = window.confirm("Are you sure you want to log out?");

        if (confirmLogout) {
            try {
                const response = await post(account_logout, true);
                const data = await response.json();

                if (data.status === false) {
                    toast.error(data.message);
                } else {
                    sessionStorage.removeItem('userData');
                    localStorage.removeItem("authtoken");
                    toast.success(data.message);
                    navigate("/");
                    window.location.reload();
                }
            } catch (error) {
                toast.error("Logout failed. Please try again.");
            }
        }
    };



    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await get(users_profile, true);
                if (!response.ok) {
                    const errorMsg = await response.text();
                    throw new Error(`Error ${response.status}: ${errorMsg}`);
                }
                const data = await response.json();

                setFormData(data.data);
                setProfilePreview( `${IMAGE_BASE_URL}${data.data.profile_photo}`  || profileimage);

            } catch (error) {
                console.error('Failed to fetch user data:', error);
            }
        };
        fetchUserData();
    }, []);

    

    return (
        <Dropdown>
            <Dropdown.Toggle className="avatar avatar-xs p-0" id="profileDropdown">
                <img className="avatar-img rounded-circle" src={profilePreview}
                    alt="avatar"
                />
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu-end shadow pt-3 px-2 py-3" style={{ minWidth: "230px" }}>

                {userData ? (
                    <Dropdown.Item as={Link} to="/user/profile-main">
                        <div className="d-flex align-items-center">
                            <div className="avatar me-3">
                                <img src={profilePreview}
                                    alt="avatar"
                                    className="avatar-img rounded-circle shadow"
                                />
                            </div>
                            <div className='username-mail'>
                                <h6 className="h6 mt-2 mt-sm-0">{formData?.name || ''}</h6>
                                <p className="small m-0">{formData?.email || ''}</p>
                            </div>
                        </div>
                    </Dropdown.Item>
                ) : ''}

                {userData ? (
                    <Dropdown.Divider />
                ) : ''}

                {userData ? (
                    <Dropdown.Item as={Link} to="/user/my-bookings" className='mychouc'>
                        <i className='me-2 far fa-bookmark'></i>
                        My Bookings
                    </Dropdown.Item>
                ) : ''}
                {/* <Dropdown.Item as={Link} to="/my-wishlist">
                    My Wishlist
                    </Dropdown.Item> */}

                {userData ? (
                    <Dropdown.Item as={Link} to="/settings" className='mychouc'>
                        <i className='me-2 far fa-sliders-v'></i>
                        Settings
                    </Dropdown.Item>
                ) : ''}

                {userData ? (
                    <Dropdown.Item onClick={handleLogout} className="bg-danger-soft-hover mychouc">
                        <i className='me-2 far fa-power-off'></i>
                        Sign Out
                    </Dropdown.Item>
                ) : ''}


                {userData ? (
                    <Dropdown.Divider />
                ) : ''}

                {!userData ? (
                    <div className="nav-pills-primary-soft theme-icon-active d-flex justify-content-around align-items-center p-2 pb-0">
                        <NavLink to="/login" className="main-menu__logi">
                            <button type="button" className="btn btn-link nav-link text-primary-hover mb-0 p-0">
                                Log In
                            </button>
                        </NavLink>
                        <NavLink to="/signin" className="main-menu__logi">
                            <button type="button" className="btn btn-link nav-link text-primary-hover mb-0 p-0">
                                Register
                            </button>
                        </NavLink>
                    </div>
                ) : ''}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default ProfileDropdownWidget;
