import React, { useState } from 'react';
import '../login-signin/login-sign.css'
import { Link,useNavigate } from 'react-router-dom';
import { post } from "../../API/apiHelper";
import { create_account } from "../../API/endpoints";
import { toast ,ToastContainer} from 'react-toastify';

const Signup = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        mobile_no: '',
        email: '',
        password: '',
        language: '',
        country: ''
    });

    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.name || !formData.mobile_no || !formData.email || !formData.password || !formData.language || !formData.country) {
            setErrorMessage('Please fill all the fields');
            return;
        }
        setErrorMessage('');
        const jsonData = JSON.stringify(formData);

        const response = await post(
            create_account, {
                data:jsonData
            }, true);

        const data = await response.json();
        if (data.status == false) {
            toast.error(data.message);
        } else {
            console.log(data.data);
            localStorage.setItem("authtoken", JSON.stringify(data.data.token));
            sessionStorage.setItem('userData', JSON.stringify(data.data)); 
            navigate("/user/profile-main");
            toast.success(data.message);
            window.location.reload();
        }

        
    };

    return (
        <section className="signup v2 bg-white">
             <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className="row align-items-center justify-content-center">
                <div className="col-lg-5 col-md-9 col-sm-10 p-lg-0">
                    <div className="container-fluid">
                        <div className="form-block">
                            <Link to="/" className="color-primary h6 mb-30">
                                <i className="fal fa-chevron-left"></i>&nbsp;&nbsp;Back To Home
                            </Link>
                            <h2 className="mb-30 light-black">Create an account</h2>
                            <h6 className="mb-24 text-center">Sign up with your Email</h6>
                            <form onSubmit={handleSubmit} className="form-group contact-form">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="mb-24">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                required
                                                placeholder="First Name"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-24">
                                            <input
                                                type="tel"
                                                className="form-control"
                                                name="mobile_no"
                                                value={formData.mobile_no}
                                                onChange={handleChange}
                                                required
                                                placeholder="Phone Number"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-24">
                                            <input
                                                type="email"
                                                className="form-control"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                                placeholder="Email"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-24">
                                            <input
                                                type="password"
                                                className="form-control"
                                                name="password"
                                                value={formData.password}
                                                onChange={handleChange}
                                                required
                                                placeholder="Password"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-24">
                                            <select
                                                name="language"
                                                className="form-control"
                                                value={formData.language}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="" disabled selected>
                                                    Language
                                                </option>
                                                <option value="deutsch">Deutsch</option>
                                                <option value="english">English</option>
                                                <option value="francais">Français</option>
                                                <option value="italiano">Italiano</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-24">
                                            <select
                                                name="country"
                                                className="form-control"
                                                value={formData.country}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="" disabled selected>
                                                    Country
                                                </option>
                                                <option value="AF">Afghanistan</option>
                                                <option value="AX">Åland Islands</option>
                                                {/* Add more country options as needed */}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button type="submit" className="cus-btn small-pad mb-24">Create Account</button>
                                    </div>
                                </div>
                            </form>
                            {errorMessage && <div className="alert-msg">{errorMessage}</div>}
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 p-0">
                    <div className="img-block">
                        <img src="https://flight.readytouse.in/assets/media/images/login.jpg" alt="Signup Visual" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Signup;

