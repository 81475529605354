import React from 'react';
import TitleBanner from '../../component/title-banner/title-banner';

const App = () => {
    return (

        <TitleBanner
            title="Flight Booking"
            leftImage="https://flight.readytouse.in/assets/media/banner/plan-h.png"
            rightImage="https://flight.readytouse.in/assets/media/banner/plane-2.png"
        />
    );
};

export default App;
