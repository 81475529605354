import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import '../header/header.css'
import Footer from '../footer/footer';
import SearchPopup from '../search-popup/search-popup'
import SideNavbar from '../sidebar-menu/sidebarmenu'
import PreLoader from '../../component/preloader/preloader'
import BackToTop from '../backtootop/backtootop'
import ProfileDropdown from '../nav-profile/nav-profile'
// import Login from '../../component/login-signin/login'

const Logo = () => (
    <div className="main-menu__logo">
        <Link to="/">
            <img src="https://flight.readytouse.in/assets/media/logo.png" alt="FlyNow" />
        </Link>
    </div>
);




const NavMenu = () => (
    <ul className="main-menu__list">
        <li><NavLink exact to="/" activeClassName="active">Home</NavLink></li>
        <li><NavLink to="/flight-listing" activeClassName="active">Flight Listing</NavLink></li>
        {/* <li><NavLink to="/flight-booking" activeClassName="active">Flight Booking</NavLink></li> */}
        <li><NavLink to="/about-us" activeClassName="active">About</NavLink></li>
        <li><NavLink to="/Contact-us" activeClassName="active">Contact</NavLink></li>
    </ul>
);

const AuthButtons = () => (
    <div className="main-menu-signup__login d-xl-flex d-none">
        {/* <NavLink to="/login" className="main-menu__login">Login</NavLink>
        <div className="center_slach d-xl-flex d-none">/</div>
        <NavLink to="/signin" className="main-menu__login">Signup</NavLink> */}
        <ProfileDropdown />
    </div>
);




function Header({ pagename, page }) {

    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const togglePopup = () => {
        setIsPopupVisible(!isPopupVisible);
    };
    const closePopup = () => {
        setIsPopupVisible(false);
    };

    ///////////

    const [isSNavbarVisible, setIsSNavbarVisible] = useState(false);

    const toggleSNavbar = () => {
        setIsSNavbarVisible(!isSNavbarVisible);
    };
    const closeSNavbar = () => {
        setIsSNavbarVisible(false);
    };

    return (
        <div>

            <PreLoader />

            <header>
                <nav className="main-menu">
                    <div className="container-fluid">
                        <div className="main-menu__block">
                            <div className="main-menu__left">
                                <Logo />
                                <div className="main-menu__nav">
                                    <NavMenu />
                                </div>
                            </div>
                            <div className="main-menu__right">
                                <a href="#" className="main-menu__search search-toggler d-xl-flex d-none" onClick={togglePopup}>
                                    <i className="fal fa-search"></i>
                                </a>
                                <AuthButtons />
                                <button href="#" className="main-menu__toggler mobile-nav__toggler" onClick={toggleSNavbar}>
                                    <i className="fa fa-bars"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </nav>

            </header>
            {page}
            <BackToTop />
            <Footer />
            {/* <SearchPopup /> */}
            <SearchPopup isActive={isPopupVisible} closePopup={closePopup} />

            <SideNavbar isSNavbarActive={isSNavbarVisible} closeSNavbar={closeSNavbar} />




        </div>

    );
}

export default Header;
