import React from 'react';
import '../visa-list/visa-list.css'


const Button = ({ label, disabled }) => (
    <button className={`cus-btn-outline ${disabled ? 'cus-btn-outline cus-btn-outline-dis' : ''}`} disabled={disabled}>
        {label}
    </button>
);

const Table = ({ data }) => (
    <table className="overflow-x-auto text-left whitespace-nowrap">
        <thead className="text-sm bg-card-table-gradient">
            <tr>
                <th className="w-[6rem] py-3 pl-5 font-semibold">Entry</th>
                <th className="w-[6rem] py-3 font-semibold">Validity</th>
                <th className="py-3 font-semibold w-28">Duration</th>
                <th className="w-32 py-3 font-semibold">Documents</th>
                <th className="py-3 font-semibold w-32">Processing Time</th>
            </tr>
        </thead>
        <tbody>
            {data.map((row, index) => (
                <tr key={index}>
                    <td className="py-1.5 pl-5">{row.entry}</td>
                    <td className="py-1.5">{row.validity}</td>
                    <td className="py-1.5">{row.duration}</td>
                    <td className="py-1.5">
                        <button className="text-blue-500 underline view-heree">View Here</button>
                    </td>
                    <td className="py-1.5 capitalize">{row.processingTime}</td>
                </tr>
            ))}
        </tbody>
    </table>
);

const Card = ({ title, dateInfo, price, tableData }) => (
    <div className="font-basier mb-5 rounded-3 border border-gray-200 bg-white">
        <div className="z-10 rounded-top-3 py-3 px-3 text-lg tracking-wider text-white vi-name">
            {title}
        </div>
        <div className="pb-2 pt-4">
            <div className="flex items-center gap-3 px-3 font-medium tracking-wider vi-erre">
                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="triangle-exclamation" class="svg-inline--fa fa-triangle-exclamation fa-xl h-6 w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="currentColor" d="M248.4 84.3c1.6-2.7 4.5-4.3 7.6-4.3s6 1.6 7.6 4.3L461.9 410c1.4 2.3 2.1 4.9 2.1 7.5c0 8-6.5 14.5-14.5 14.5H62.5c-8 0-14.5-6.5-14.5-14.5c0-2.7 .7-5.3 2.1-7.5L248.4 84.3zm-41-25L9.1 385c-6 9.8-9.1 21-9.1 32.5C0 452 28 480 62.5 480h387c34.5 0 62.5-28 62.5-62.5c0-11.5-3.2-22.7-9.1-32.5L304.6 59.3C294.3 42.4 275.9 32 256 32s-38.3 10.4-48.6 27.3zM288 368a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm-8-184c0-13.3-10.7-24-24-24s-24 10.7-24 24v96c0 13.3 10.7 24 24 24s24-10.7 24-24V184z"></path>
                </svg>
                {dateInfo && <span className="font-medium">{dateInfo}</span>}
            </div>
        </div>
        <div className="grid grid-cols-7 gap-2 py-3">
            <div className="col-span-5 overflow-x-auto">
                <Table data={tableData} />
            </div>
            <div className="d-flex align-items-center gap-3 flex-col items-end pr-4">
                <div className="flex items-center gap-0 font-medium">
                    ₹{price}
                    <button type="button" aria-expanded="false" className="circle-infoss">
                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="circle-info" className="svg-inline--fa fa-circle-info" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" color="gray">
                            <path fill="currentColor" d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8V248c0-13.3-10.7-24-24-24H216c-13.3 0-24 10.7-24 24s10.7 24 24 24h24v64H216zm40-144a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" />
                        </svg>
                    </button>
                </div>
                <Button label="Select" />
            </div>
        </div>
    </div>
);

function App() {
    const cardData1 = {
        title: "30 Days Single Entry",
        dateInfo: "Your visa will be delivered on 9th Oct, 2024 at 5:00 PM",
        price: "6,599",
        tableData: [
            { entry: "Single", validity: "60 days", duration: "30 days", processingTime: "4 business days" }
        ]
    };

    const cardData2 = {
        title: "Atlys PRO Services",
        dateInfo: "Guaranteed visa on 7th Oct, 2024 at 8:00 PM",
        price: "4,553",
        tableData: [
            { entry: "Single", validity: "60 days", duration: "30 days", processingTime: "1 business day" }
        ]
    };

    const cardData3 = {
        title: "Ok To Board (OTB)",
        price: "500",
        tableData: [
            { entry: "Single", validity: "60 days", duration: "30 days", processingTime: "2 business days" }
        ]
    };

    return (
        <div className=" flex flex-col max-w-screen-xl mt-4 m-4">
            <Card {...cardData1} />
            <Card {...cardData2} />
            <Card {...cardData3} />
        </div>
    );
}

export default App;